import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import baseInstance from "../api";
import { usePrice } from "../context/Price";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import CommonLayout from "../components/layout/CommonLayout";
import { checkEmailQuota } from "../helpers/api";
import { Spinner } from "flowbite-react";

// Updated validation schema with all new fields
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  full_name: yup.string().required("Full name is required"),
  givenName: yup.string(),
  surname: yup.string(),
  username: yup.string(),
  companyName: yup.string().required("Company name is required"),
  jobTitle: yup.string(),
  streetAddress: yup.string().required("Street address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State/Province is required"),
  country: yup.string().required("Country is required"),
  postalCode: yup.string().required("Postal code is required"),
  email_is_checked: yup.boolean(),
  receive_ads: yup.boolean(),
});

const RegisterPremium = () => {
  const [loading, setLoading] = useState(false);
  const [checkingQuota, setCheckingQuota] = useState(false);
  const [emailQuota, setEmailQuota] = useState<{
    remaining_quota: number;
    free_quota: number;
  } | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { setPrice }: any = usePrice();
  const { t } = useTranslation("home");

  const { reportData } = location.state || {};


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const email = watch("email");

  // Quota check effect
  useEffect(() => {
    const checkQuota = async () => {
      if (email && email.includes("@")) {
        setCheckingQuota(true);
        try {
          const quotaData = await checkEmailQuota(email);
          setEmailQuota({
            remaining_quota: quotaData.remaining_quota,
            free_quota: quotaData.free_quota,
          });
        } catch (error) {
          setEmailQuota({
            remaining_quota: 5,
            free_quota: 5,
          });
        } finally {
          setCheckingQuota(false);
        }
      }
    };

    const timer = setTimeout(checkQuota, 500);
    return () => clearTimeout(timer);
  }, [email]);

  const onSubmit = async (data: any) => {
    if (emailQuota && emailQuota.remaining_quota <= 0) {
      const firstName = data.givenName || data.full_name.split(" ")[0];
      navigate("/success-premium", {
        state: {
          message: t(
            "You've used all your free reports ({{used}}/{{total}}). Upgrade to continue accessing insights.",
            {
              used: emailQuota.free_quota,
              total: emailQuota.free_quota,
            }
          ),
          email: data.email,
          firstName: firstName,
        },
      });
      return;
    }

    setLoading(true);
    try {
      const business_type = reportData.crop ? "ecocrop" : "livestock";
      const { report_id } = reportData;

      // Prepare payload with all fields
      const payload = {
        ...data,
        business_type,
        report_id,
      };

      const res = await baseInstance.post("/v1/b2c/premium/register", payload);

      setPrice(res?.data);

      // Handle successful registration
      // ...
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: t("Oops"),
        text: t("Something went wrong. Please try again."),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonLayout isNavDark={true}>
      <div className="min-h-screen flex flex-col justify-start py-12 pt-8 sm:px-6 lg:px-8 transition-all duration-2000 ease-in-out bg-gradient-to-b from-blue-50 to-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <h2 className="mt-6 text-center text-2xl font-bold text-black animate-fade-in">
            {t("You're Seconds Away from Premium...")}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg animate-slide-up">
          <div className="bg-white/90 backdrop-blur-sm py-10 px-8 shadow-xl rounded-lg sm:px-12 border border-gray-100">
            <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
              {/* Personal Information Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Personal Information
                </h3>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="givenName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("First Name")} *
                    </label>
                    <input
                      id="givenName"
                      type="text"
                      {...register("givenName")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="surname"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Last Name")} *
                    </label>
                    <input
                      id="surname"
                      type="text"
                      {...register("surname")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="full_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Full Name")} *
                  </label>
                  <input
                    id="full_name"
                    type="text"
                    {...register("full_name")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                  {errors.full_name && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.full_name.message}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Username")}
                  </label>
                  <input
                    id="username"
                    type="text"
                    {...register("username")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Email")} *
                  </label>
                  <div className="mt-1 relative">
                    <input
                      id="email"
                      type="email"
                      {...register("email")}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                    {checkingQuota && (
                      <div className="absolute right-3 top-2.5">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </div>
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.email.message}
                    </p>
                  )}
                  {emailQuota && (
                    <div
                      className={`text-sm mt-1 ${
                        emailQuota.remaining_quota > 0
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {/* Reports remaining: {emailQuota.remaining_quota}/
                      {emailQuota.free_quota} */}
                    </div>
                  )}
                </div>
              </div>

              {/* Professional Information Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Professional Information
                </h3>

                <div>
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Company Name")} *
                  </label>
                  <input
                    id="companyName"
                    type="text"
                    {...register("companyName")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                  {errors.companyName && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.companyName.message}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="jobTitle"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Job Title")}
                  </label>
                  <input
                    id="jobTitle"
                    type="text"
                    {...register("jobTitle")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                </div>
              </div>

              {/* Address Information Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Address Information
                </h3>

                <div>
                  <label
                    htmlFor="streetAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("Street Address")} *
                  </label>
                  <input
                    id="streetAddress"
                    type="text"
                    {...register("streetAddress")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                  {errors.streetAddress && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.streetAddress.message}
                    </p>
                  )}
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("City")} *
                    </label>
                    <input
                      id="city"
                      type="text"
                      {...register("city")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                    {errors.city && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.city.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("State/Province")} *
                    </label>
                    <input
                      id="state"
                      type="text"
                      {...register("state")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                    {errors.state && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.state.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="postalCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Postal Code")} *
                    </label>
                    <input
                      id="postalCode"
                      type="text"
                      {...register("postalCode")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                    {errors.postalCode && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.postalCode.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Country")} *
                    </label>
                    <input
                      id="country"
                      type="text"
                      {...register("country")}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                    />
                    {errors.country && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.country.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Preferences Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Preferences
                </h3>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="email_is_checked"
                      type="checkbox"
                      {...register("email_is_checked")}
                      className="focus:ring-[#002d62] h-4 w-4 text-[#002d62] border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="email_is_checked"
                      className="font-medium text-gray-700"
                    >
                      {t("I agree to receive product updates via email")}
                    </label>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="receive_ads"
                      type="checkbox"
                      {...register("receive_ads")}
                      className="focus:ring-[#002d62] h-4 w-4 text-[#002d62] border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="receive_ads"
                      className="font-medium text-gray-700"
                    >
                      {t("I agree to receive promotional offers")}
                    </label>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="pt-4">
                <button
                  type="submit"
                  className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                    bg-gradient-to-r from-[#7A5A44] to-[#002d62] 
                    ${
                      loading
                        ? "cursor-not-allowed opacity-75"
                        : "hover:from-[#002d62] hover:to-[#7A5A44]"
                    } 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#002d62] transition-all duration-200`}
                  disabled={loading}
                >
                  {loading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                      ></path>
                    </svg>
                  ) : (
                    t("Complete Premium Registration")
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default RegisterPremium;
