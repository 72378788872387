import { useState } from "react";
import { motion } from "framer-motion";

const PillerCard = ({ title, image, hoverHeading, hoverText }: any) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative cursor-pointer overflow-hidden rounded-xl shadow-lg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Image (always visible) */}
      <img src={image} alt={title} className="w-full h-full object-cover" />

      {/* Title (always visible at the bottom) */}
      <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-70 text-black p-4 text-center">
        <h3 className="text-xl font-bold">{title}</h3>
      </div>

      {/* Hover Text (visible only on hover) */}
      {isHovered && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="absolute inset-0 bg-[#002d62] bg-opacity-60 flex flex-col items-center justify-center text-white p-4 rounded-xl"
        >
          <h3 className="text-xl font-bold mb-2">{hoverHeading}</h3>
          <p className="text-">{hoverText}</p>
        </motion.div>
      )}
    </div>
  );
};

export default PillerCard;
