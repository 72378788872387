import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonLayout from "../components/layout/CommonLayout";
import { CheckCircle, Mail, Zap, Headphones } from "lucide-react";

const PremiumRegistrationSuccess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email = state?.email || "your email";

  return (
    <CommonLayout isNavDark={true}>
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          {/* Success Icon */}
          <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 mb-6">
            <CheckCircle className="h-10 w-10 text-green-600" />
          </div>

          {/* Main Heading */}
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {t("Premium Account Activated!")}
          </h1>

          {/* Subheading */}
          <p className="text-xl text-gray-600 mb-8">
            {t("Thank you for upgrading to Premium")}
          </p>

          {/* Verification Card */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-8 text-left">
            <div className="flex items-start">
              <Mail className="h-6 w-6 text-blue-500 mt-1 mr-4" />
              <div>
                <h2 className="text-lg font-medium mb-2">
                  {t("Verify Your Email")}
                </h2>
                <p className="text-gray-600">
                  {t("We've sent a confirmation to")}{" "}
                  <span className="font-semibold">{email}</span>.{" "}
                  {t(
                    "Please check your inbox and click the verification link."
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Benefits Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
            {/* Immediate Access */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex items-center mb-3">
                <Zap className="h-5 w-5 text-yellow-500 mr-2" />
                <h3 className="font-medium">{t("Instant Access")}</h3>
              </div>
              <p className="text-gray-600 text-sm">
                {t(
                  "Start using all premium features immediately after email verification"
                )}
              </p>
            </div>

            {/* Support */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex items-center mb-3">
                <Headphones className="h-5 w-5 text-blue-500 mr-2" />
                <h3 className="font-medium">{t("Priority Support")}</h3>
              </div>
              <p className="text-gray-600 text-sm">
                {t(
                  "Get dedicated support for any questions about your premium account"
                )}
              </p>
            </div>
          </div>

          {/* <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => navigate("/dashboard")}
              className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-medium transition-colors"
            >
              {t("Go to Dashboard")}
            </button>
            <button
              onClick={() => navigate("/premium/features")}
              className="px-6 py-3 border border-gray-300 hover:bg-gray-50 rounded-lg font-medium transition-colors"
            >
              {t("Explore Features")}
            </button>
          </div> */}

          <p className="mt-8 text-gray-500 text-sm">
            {t("Need help?")}{" "}
            <button
              onClick={() => navigate("/contact")}
              className="text-blue-600 hover:underline"
            >
              {t("Contact our support team")}
            </button>
          </p>
        </div>
      </div>
    </CommonLayout>
  );
};

export default PremiumRegistrationSuccess;
