import CommonLayout from "../components/layout/CommonLayout";
import {
  PlayCircle,
  BookOpen,
  Download,
  BarChart2,
  Zap,
  Settings,
  Users,
  Mail,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TutorialPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tutorialSections = [
    {
      title: t("Getting Started"),
      icon: <Zap className="h-6 w-6 text-blue-600" />,
      items: [
        {
          title: t("Activating Your Account"),
          description: t(
            "Step-by-step guide to setting up your premium features"
          ),
          video: "https://example.com/videos/activation",
          pdf: "/pdfs/activation-guide.pdf",
        },
        {
          title: t("Navigating the Dashboard"),
          description: t("Learn how to access all premium tools and reports"),
          video: "https://example.com/videos/dashboard",
          pdf: "/pdfs/dashboard-guide.pdf",
        },
      ],
    },
    {
      title: t("Advanced Features"),
      icon: <BarChart2 className="h-6 w-6 text-green-600" />,
      items: [
        {
          title: t("Using Predictive Analytics"),
          description: t("How to interpret and apply data insights"),
          video: "https://example.com/videos/analytics",
          pdf: "/pdfs/analytics-guide.pdf",
        },
        {
          title: t("Custom Report Generation"),
          description: t("Create tailored reports for your specific needs"),
          video: "https://example.com/videos/reports",
          pdf: "/pdfs/report-guide.pdf",
        },
      ],
    },
    {
      title: t("Account Management"),
      icon: <Settings className="h-6 w-6 text-purple-600" />,
      items: [
        {
          title: t("Updating Your Subscription"),
          description: t("How to modify your plan or payment method"),
          video: "https://example.com/videos/subscription",
          pdf: "/pdfs/subscription-guide.pdf",
        },
        {
          title: t("Team Access Setup"),
          description: t("Add team members and manage permissions"),
          video: "https://example.com/videos/team-access",
          pdf: "/pdfs/team-guide.pdf",
        },
      ],
    },
  ];

  return (
    <CommonLayout isNavDark={true}>
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {t("Tutorials & Resources")}
          </h1>
          <p className="text-xl text-gray-600">
            {t(
              "Learn how to maximize your experience with our comprehensive guides"
            )}
          </p>
        </div>

        {/* Search Bar */}
        <div className="max-w-2xl mx-auto mb-12">
          <div className="relative">
            <input
              type="text"
              placeholder={t("Search tutorials...")}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <button className="absolute right-3 top-3 text-gray-400 hover:text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Tutorial Sections */}
        <div className="max-w-7xl mx-auto space-y-12">
          {tutorialSections.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
              className="bg-white rounded-xl shadow-md overflow-hidden"
            >
              <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
                <div className="flex items-center">
                  {section.icon}
                  <h2 className="ml-3 text-xl font-semibold text-gray-900">
                    {section.title}
                  </h2>
                </div>
              </div>

              <div className="divide-y divide-gray-200">
                {section.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="p-6">
                    <div className="flex flex-col md:flex-row md:items-center justify-between">
                      <div className="mb-4 md:mb-0 md:mr-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-1">
                          {item.title}
                        </h3>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                      <div className="flex space-x-3">
                        <a
                          href={item.video}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                        >
                          <PlayCircle className="h-4 w-4 mr-2 text-blue-600" />
                          {t("Watch Video")}
                        </a>
                        <a
                          href={item.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                        >
                          <Download className="h-4 w-4 mr-2 text-green-600" />
                          {t("Download PDF")}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Support Section */}
        <div className="max-w-7xl mx-auto mt-16 bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-gradient-to-r from-[#7A5A44] to-[#002d62] px-6 py-4">
            <div className="flex items-center">
              <Users className="h-6 w-6 text-white" />
              <h2 className="ml-3 text-xl font-semibold text-white">
                {t("Need Additional Help?")}
              </h2>
            </div>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {t("Contact Our Support Team")}
                </h3>
                <p className="text-gray-600 mb-4">
                  {t(
                    "Our dedicated support specialists are available to help you with any questions"
                  )}
                </p>
                <button
                  onClick={() => navigate("/contact")}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-[#7A5A44] to-[#002d62] hover:bg-blue-700"
                >
                  <Mail className="h-4 w-4 mr-2" />
                  {t("Contact Support")}
                </button>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {t("Knowledge Base")}
                </h3>
                <p className="text-gray-600 mb-4">
                  {t("Browse our comprehensive library of articles and FAQs")}
                </p>
                <button
                  onClick={() => navigate("/knowledge-base")}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
                >
                  <BookOpen className="h-4 w-4 mr-2 text-blue-600" />
                  {t("Visit Knowledge Base")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default TutorialPage;
