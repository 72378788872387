// import { useLocation, useNavigate } from "react-router-dom";
import CommonLayout from "../components/layout/CommonLayout";
// import PricingCard from "../components/PricingCard";
// import { usePrice } from "../context/Price";
// import { useTranslation } from "react-i18next";
import img from "../../public/img/coming-soon-background-illustration-template-design-free-vector.jpg";

const Plans = () => {
  // const { price }: any = usePrice();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const { t } = useTranslation("home");
  // const searchParams = new URLSearchParams(location.search);

  // const email = searchParams.get("email");
  // const fullname = searchParams.get("fullname");
  // const userId = searchParams.get("userId");

  const apiUrl = `v1/subscription/b2c/premium/topup/product-list`;

  fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); 
    })
    .then((data) => {
      console.log(data)
      console.log("Data fetched successfully:", data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  return (
    <CommonLayout isNavDark={true}>
      <div style={styles.container}>
        {/* Image */}
        <div style={styles.imageContainer}>
          <img src={img} alt="Coming Soon" style={styles.image} />
        </div>

        {/* Description */}
        <p style={styles.description}>
          We're working hard to bring you something amazing. Stay tuned!
        </p>

        {/* Countdown Timer */}
        <div style={styles.countdown}>
          <div style={styles.countdownItem}>
            <span id="days" style={styles.countdownNumber}>
              00
            </span>
            <small style={styles.countdownLabel}>Days</small>
          </div>
          <div style={styles.countdownItem}>
            <span id="hours" style={styles.countdownNumber}>
              00
            </span>
            <small style={styles.countdownLabel}>Hours</small>
          </div>
          <div style={styles.countdownItem}>
            <span id="minutes" style={styles.countdownNumber}>
              00
            </span>
            <small style={styles.countdownLabel}>Minutes</small>
          </div>
          <div style={styles.countdownItem}>
            <span id="seconds" style={styles.countdownNumber}>
              00
            </span>
            <small style={styles.countdownLabel}>Seconds</small>
          </div>
        </div>
      </div>
      {/* <div className="bg-gradient-to-br from-indigo-100 to-purple-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-extrabold text-indigo-900 text-center mb-4">
            {t("Choose Your Package")}
          </h2>
          <p className="text-xl text-indigo-700 text-center mb-12">
            {t("Select the perfect plan for your needs")}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {price?.products?.map((product: any, index: any) => (
              <div
                onClick={() => {
                  navigate(
                    `/plancheckout?email=${encodeURIComponent(
                      //@ts-ignore
                      email
                    )}&fullname=${encodeURIComponent(
                      //@ts-ignore
                      fullname
                    )}&userId=${encodeURIComponent(
                      //@ts-ignore
                      userId
                    )}&priceId=${encodeURIComponent(product.price_id)}`
                  );
                }}
              >
                <PricingCard
                  email={email}
                  fullname={fullname}
                  userId={userId}
                  key={product.price_id}
                  product={product}
                  isPopular={index === 1}
                />
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </CommonLayout>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const, // Explicitly set as a valid CSS value
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f4f4f9",
    padding: "20px",
    textAlign: "center" as const, // Explicitly set as a valid CSS value
  },
  imageContainer: {
    marginBottom: "20px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#333",
    marginBottom: "20px",
  },
  description: {
    fontSize: "1.2rem",
    color: "#666",
    marginBottom: "30px",
  },
  countdown: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "30px",
    width: "100%",
    maxWidth: "400px",
  },
  countdownItem: {
    backgroundColor: "#002d62",
    color: "#fff",
    padding: "15px",
    borderRadius: "10px",
    width: "80px",
    textAlign: "center" as const, // Explicitly set as a valid CSS value
  },
  countdownNumber: {
    display: "block",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  countdownLabel: {
    fontSize: "0.9rem",
    color: "#e0e0e0",
  },
  socialLinks: {
    marginTop: "20px",
  },
  socialLink: {
    margin: "0 10px",
    textDecoration: "none",
    color: "#007bff",
    fontSize: "1.2rem",
    transition: "color 0.3s ease",
  },
  socialLinkHover: {
    color: "#0056b3",
  },
};
export default Plans;
