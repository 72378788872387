//@ts-nocheck

import cropsImg from "../assets/icons/crop.svg";
import location from "../assets/icons/location.svg";
import Select from "react-select";

// import { useForm, SubmitHandler } from "react-hook-form";

// type Inputs = {
//   example: string;
//   exampleRequired: string;
// };
import time from "../assets/icons/time.svg";
import Places from "./PlacesAutocomplete";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import { useMediaQuery } from "react-responsive";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";
import PartialReport from "./PartialReport";
import { Button, Label, Modal } from "flowbite-react";
import baseInstance from "../api";
import { formatDate } from "../utils";
import axios from "axios";
import ModalSelf from "./Modal";
import PartialReportLivestock from "./PartialReportLivestock";
import Turnstile from "./Turnstile";
import TurnstileWidget from "./Turnstile";
import FormCaptcha from "./FormCaptcha";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";
import i18next from "i18next";
import SignupPage from "../pages/SignUp";
import RegisterForm from "../pages/Register";
import { useNavigate } from "react-router-dom"; 


export const Hero = ({ openMapPopup, setOpenMapPopup }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [selected, setSelected] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [fade, setFade] = useState(true);
  const [isProtected, setIsProtected] = useState(false);
  const [allCrops, setAllCrops] = useState([]);
  const [reportData, setReportData] = useState({});
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [profitData, setProfitData] = useState({});
  const [reportId, setReportId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [source, setSource] = useState("");
  const [currentBreed, setCurrentBreed] = useState(null);
  const type = useRef(null);
  const selectedOption = useRef(null);
  const [months, setMonths] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [isLivestock, setIsLivestock] = useState(false);
  const [option, setOption] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [riskId, setRiskId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstQuestion, setFirstQuestion] = useState([]);
  const [livestock, setLivestock] = useState([]);
  const [step, setStep] = useState(0);
  const [sumbmit, setSumbit] = useState({});
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const images = [image1, image2, image3, image4];
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [isComputed, setIsComputed] = useState(false);
  const [livestockData, setIsLivestockData] = useState([]);
  const [inputValue, setinputValue] = useState(null);
  const [reportIdLivestock, setReportIdLivestock] = useState("");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [debugInfo, setDebugInfo] = useState("");
  const [turnstileToken, setTurnstileToken] = useState("");
  const [firstAnswer, setFirstAnswer] = useState("");
  const [openCapPopup, setOpenCapPopup] = useState(false);
  const [breedTypes, setBreedTypes] = useState([]);
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const languageMapping = {
    zh: "chinese",
    en: "english",
    tl: "filipino",
    fr: "french",
    de: "german",
    hi: "hindi",
    id: "indonesian",
    ja: "japanese",
    pt: "portuguese",
    ru: "russian",
    es: "spanish",
  };
  // Ensure questionnaires are fully loaded and log them
  useEffect(() => {
    setDebugInfo(
      `Question ${currentQuestionIndex + 1} of ${questionnaires.length}`
    );
  }, [questionnaires, currentQuestionIndex]);

  const currentQuestion = questionnaires[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questionnaires.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  const handleAnswer = (value) => {
    // Use `category_type` instead of `category_name` to store the answer
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion?.category_type]: value,
    }));
  };
  // Move to the next question or submit if it's the last one
  const handleNext = async () => {
    if (!currentQuestion) {
      return;
    }


    // Ensure we don't go out of bounds
    if (currentQuestionIndex < questionnaires.length - 1) {
      // Reset answer for the next question to avoid pre-filling
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      await handleSubmit(); // If last question, submit the answers
    }
  };

  // @ts-ignore
  const CROP_URL = "v1/public/eco-crop/eco-crop-type?page=1&per_page=5000";
  const COMPUTE_SCORE_CROP = "v1/b2c/free-trial/eco-crop/compute-score";
  async function fetchCrops() {
    const res = await baseInstance.get(
      "v1/b2c/free-trial/eco-crop/list-all?page=1&per_page=5000"
    );
    // const res = await baseInstance.get(
    //   "v1/public/eco-crop/eco-crop-type?page=1&per_page=5000"
    // );
    const cropDate = res.data.data.map((item) => {
      return {
        name: t(item), // Translate the crop name
        value: item, // Keep the original crop value
      };
    });

    // Sort the translated crop names using locale-aware sorting
    const collator = new Intl.Collator(i18next.language); // Use the current i18next language
    const sortedData = cropDate.sort((a, b) =>
      collator.compare(a.name, b.name)
    );


    setAllCrops(cropDate);
  }

  useEffect(() => {
    fetchCrops();
  }, [selectedLanguage]);

  const waterSource = [
    { name: t("Rainfed"), value: "rainfed" },
    { name: t("Rainfed & Irrigation"), value: "rainfed_irrigation" },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 400); // Match this duration with the CSS transition duration
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    fetchCrops();
    getLivestock();
  }, []);

  useEffect(() => {
    // Reset currentAnswer when moving to a new question
    setCurrentAnswer("");
  }, [currentQuestionIndex]);
  const customStyles = {
    control: (provided) => ({
      ...provided,

      //for mobile css end
      paddingLeft: "2rem", // pl-10
      paddingRight: "0.5rem", // pr-4
      paddingTop: isMobile ? "0.2px" : "0.4rem", // py-4 (top)
      paddingBottom: isMobile ? "0.2px" : "0.4rem", // py-4 (bottom)
      borderColor: "#D1D5DB", // Tailwind gray-300 border color
      borderRadius: "9999px", // rounded-full
      width: "100%", // w-full
      height: isMobile ? "32px" : "", // w-full
      minHeight: isMobile ? "32px" : "", // w-full
      color: "#9CA3AF", // text-gray-400
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#9CA3AF", // text-gray-400
    }),
    menu: (provided) => ({
      ...provided,
      // zIndex: 50, // z-50 to ensure dropdown visibility
    }),
  };
  const handleMonthsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputMonths = e.target.value;
    setMonths(inputMonths);

    if (inputMonths && !isNaN(Number(inputMonths))) {
      const numberOfMonths = parseInt(inputMonths, 10);
      const newStartDate = new Date(); // Use current date as start date
      const newEndDate = new Date(newStartDate);
      newEndDate.setMonth(newEndDate.getMonth() + numberOfMonths);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      // Reset dates if input is invalid
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };
  const handlelosePopUp = (e: any) => {
    if (e.target.id === "ModelContainer") {
      setOpenMapPopup(false);
    }
  };
 async function getCompositeScore() {
  try {
    // Set loading state and open the CAP popup
    setLoading(true);
    setOpenCapPopup(true);

    // Make the API request
    const res = await baseInstance.post(COMPUTE_SCORE_CROP, {
      crop: selectedCrop,
      latitude: selected?.lat,
      longitude: selected?.lng,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      locationName: address,
      water_source: source,
    });
    setLoading(false)
    setReportId(res.data.report_id);
    setReportData(res.data);
    setIsModalOpen(true)

    // navigate("/register", { state: { reportData: res.data } });

    setOpenCapPopup(false);

    // Return the report data for further use (if needed)
    return res.data;  
  } catch (error) {
    // Handle errors gracefully
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: t("Something went wrong"),
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });

    // Optionally, rethrow the error if you want to handle it elsewhere
    throw error;
  } finally {
    // Ensure loading and CAP popup are always reset
    setLoading(false);
    setOpenCapPopup(false);
  }
}
async function getLivestock() {
    const GET_LIVESTOCK = `/v1/b2c/free-trial/livestock/livestock-type`;
    baseInstance({ method: "GET", url: GET_LIVESTOCK })
      .then((data) => {
        const resultArray = [];
        const fullData = data.data;
        for (const key of Object.keys(fullData)) {
          resultArray.push({ label: t(fullData[key]), value: key });
        }
        setLivestock(resultArray);
        // });
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  const handleSubmit = async (state) => {
    setOpen(false);
    setOpenCapPopup(true);

    const body = {
      livestock_type: option.value,
      parameters: { ...answers },
    };

    body.report_id = reportId;

    body.parameters.system = firstAnswer;

    body.session_id = sessionId;
    const URL_PROFIT = `/v1/b2c/free-trial/livestock/compute-profitability-risk`;
    setIsLoading(true);
    baseInstance<any>({
      method: "POST",
      url: URL_PROFIT,
      data: body,
    })
      .then((data) => {
        setOption(null);
        setCurrentBreed("");
        setOpenCapPopup(false);
        setReportIdLivestock(data.data.report_id);
        setProfitData(data?.data);
        setIsComputed(true);
        setIsLoading(false);
        setIsModalOpen(true);
        const specificScores = [
          "water_scores",
          "profitability_scores",
          "climate_scores",
        ];
        const result = [];
      })
      .catch(() => setOpenCapPopup(false));
    // setOption(null);
    setSumbit({});
    setStep(0);
    setQuestions([]);
  };
  async function handleChangeFirstQuestion(item) {
    setCurrentQuestionIndex(0);
    setAnswers({});
    setIsSubmitting(false);
    setSubmitMessage("");
    setDebugInfo("");

    setFirstAnswer(item.value);
    setSumbit({ ...sumbmit, [type.current]: item.value });

    setError(null);
    setOpenModal(false);
    const REST_QUESTIONS = `/v1/public/livestock/questionnaire/others?lang=${languageMapping[selectedLanguage]}`;
    const payload = {
      livestock_type: selectedItem,
      system: item.value,
    };
    baseInstance<any>({
      method: "POST",
      url: REST_QUESTIONS,
      data: payload,
    })
      .then((data) => {

        setQuestionnaires(data?.data?.questionnaires);
        setOpen(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  const handleVerify = (token) => {
    setTurnstileToken(token);
    // You can now send this token to your backend for verification
  };
  function getFormQuestions(type, selectedItem) {
    const CHECK_SCORE = `/v1/b2c/free-trial/livestock/compute-climate-water-risk`;
    // const URL_Questions = `/questionnaire/system-only/${type}`;
    const URL_Questions = `/v1/b2c/free-trial/livestock/questionnaire/system-only/${selectedItem}?lang=${languageMapping[selectedLanguage]}`;

    // console.log(type, "submit data for now");

    // "endDate": endDate,
    // "latitude": -0.023559,
    // "livestock_type": "dairy_cattle",
    // "locationName": "Kenya",
    // "longitude": 37.906193,
    // "startDate": "2024-03-14"
    const payload = {
      crop: selectedCrop,
      latitude: selected?.lat,
      longitude: selected?.lng,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      locationName: address,
    };

    delete payload.crop;
    payload.livestock_type = selectedItem;
    payload.breed_type = type;
    Swal.fire({
      title: t("Evaluating location"),
      html: t("Please wait..."),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    baseInstance<any>({ method: "POST", url: CHECK_SCORE, data: payload })
      .then((data) => {
        Swal.close();

        setReportId(data?.data?.report_id);
        setSessionId(data?.data?.session_id);

        if (Math.floor(data?.data?.thi_risk) > 74) {
          Swal.fire({
            icon: "error",
            title:
              "<h5 style='color:#f27474'>" +
              "Climate & Water Risk Alert" +
              "</h5>",
            text: t(
              "The Temperature Humidity Risk is too high! Please, choose another breed!"
            ),
          });
          return;
        }

        if (
          Math.floor(data?.data?.composite_climate_risk) > 74 &&
          Math.floor(data?.data?.composite_water_risk) > 74
        ) {
          Swal.fire({
            icon: "error",
            title:
              "<h5 style='color:red'>" + "Climate & Water Risk Alert" + "</h5>",
            text: t("The Climate and Water Risk is too high. Do not proceed!"),
          });
          return;
        }
        if (Math.floor(data?.data?.composite_water_risk) > 74) {
          Swal.fire({
            icon: "error",
            title:
              "<h5 style='color:#f27474'>" +
              "Climate & Water Risk Alert" +
              "</h5>",
            text: t("The Water Risk is too high! Do not proceed!"),
          });
          return;
        }

        setOpenModal(true);
        setIsLoading(true);
        baseInstance<any>({ method: "GET", url: URL_Questions })
          .then((data) => {
            setQuestions(data?.data?.questionnaires);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
        if (Math.floor(data?.data?.composite_climate_risk) > 74) {
          Swal.fire({
            icon: "warning",
            title:
              "<h5 style='color:orange'>" +
              t("Climate & Water Risk Warning") +
              "</h5>",
            text: t(
              "The area you selected has a very high climate risk. Do you still want to compute the score?"
            ),
            showDenyButton: true,
            // showCancelButton: false,
            confirmButtonText: t("Yes"),
            denyButtonText: t(`No`),
          }).then((result) => {
            if (result.isConfirmed) {
              setIsLoading(true);
              setOpenModal(true);
              axiosProfitablity<any>({ method: "GET", url: URL_Questions })
                .then((data) => {

                  setFirstQuestion(data?.data?.questionnaires);
                  setIsLoading(false);
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
            }

            if (result.isDenied || result.isDismissed) {
              return;
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "<h5 style='color:red'>" + "Error" + "</h5>",
          text: t("The Climate and Water Risk is too high. Do not proceed!"),
        });
      });
  }

  const handleCheckboxChange = (event) => {
    setIsLivestock(event.target.checked);
  };

  const handleChanget = (item) => {
    const BREED_TYPE = `/v1/b2c/free-trial/livestock/breed-type/${item.value}`;
    setOption(item);
    baseInstance<any>({
      method: "GET",
      url: BREED_TYPE,
    })
      .then((data) => {
        const breedData = data.data.map((item) => {
          return {
            label: item,
            value: item,
          };
        });

        setBreedTypes(breedData);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };
  const handlePrevious = () => {
    if (!isFirstQuestion) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };
  function handleChangeBreed(item) {
    setCurrentBreed({ label: item.value, value: item.value });
  }
  const renderQuestionInput = () => {
    if (!currentQuestion) {
      // console.error("Current question is undefined");
      return <p>Error: Question not found</p>;
    }

    if (currentQuestion?.choices && currentQuestion?.choices.length > 0) {
      const options = currentQuestion?.choices.map((choice) => ({
        value: choice,
        label: choice,
      }));

      return (
        <Select
          options={options}
          placeholder={t("Select")}
          onChange={(selectedOption) =>
            handleAnswer(selectedOption ? selectedOption.value : "")
          }
          value={
            options.find(
              (option) =>
                option.value === answers[currentQuestion?.category_type]
            ) || null
          }
          isClearable={true}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
              color: "black",
            }),
          }}
        />
      );
    } else {
      return (
        <input
          type="number"
          value={answers[currentQuestion?.category_type] || ""}
          onChange={(e) => handleAnswer(Number(e.target.value))}
          placeholder="Enter a number"
        />
      );
    }
  };
return (
  <>
    <div className="lg:flex block mt-1 md:mt-10 relative ml-5">
      {/* Left Section: Form and Content */}
      <div className="md:relative w-full lg:w-[90%] text-center lg:text-left">
        {/* Gradient Box */}
        <div className="text-white px-6 py-4 md:px-8 md:py-12 bg-gradient-to-r from-[#002d62] to-[#7A5A44] rounded-2xl shadow-xl">
          {/* <div className="text-white px-6 py-4 md:px-8 md:py-12 bg-gradient-to-r from-[#002d62] to-[#002d62] rounded-2xl shadow-xl"> */}
          <h1 className="text-4xl font-extrabold md:text-5xl">{t("h1")}</h1>
          <p className="text-lg md:text-2xl mt-2 font-medium">{t("h2")}</p>
        </div>

        {/* Form Section */}
        {/* Form Section */}
        <div className="mt-8 p-6 md:p-10 bg-white/80 backdrop-blur-sm border border-white/20 rounded-3xl shadow-lg shadow-blue-100/50">
          <p className="text-2xl md:text-3xl font-semibold text-center text-gray-800">
            {t("get your climate score")}
          </p>

          {/* Toggle Switch for Crops/Livestock */}
          <div className="mt-6 flex justify-between items-center max-w-xs mx-auto px-4">
            <p className="text-lg text-gray-700">{t("crops")}</p>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                id="switch"
                checked={isLivestock}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="peer sr-only"
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-300/80 after:absolute after:left-1 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:shadow-sm after:transition-all peer-checked:bg-[#002d62]/90 peer-checked:after:translate-x-full peer-focus:ring-2 peer-focus:ring-[#6cb4e4]/50"></div>
            </label>
            <p className="text-lg text-gray-700">{t("livestock")}</p>
          </div>

          {/* Form Inputs */}
          <div className="mt-6 space-y-5">
            {/* Location Input */}
            <div className="relative">
              <input
                value={address}
                onFocus={() => setOpenMapPopup(true)}
                type="text"
                className="pl-10 pr-4 py-3 text-lg border border-gray-200/80 bg-white/90 rounded-full w-full focus:ring-2 focus:ring-[#6cb4e4]/50 focus:border-transparent"
                placeholder={t("enter your location")}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  loading="lazy"
                  src={location}
                  alt="location icon"
                  className="opacity-70"
                />
              </div>
            </div>

            {/* Planning Period Input */}
            <div className="relative">
              <input
                type="number"
                onChange={handleMonthsChange}
                className="pl-10 pr-4 py-3 text-lg border border-gray-200/80 bg-white/90 rounded-full w-full focus:ring-2 focus:ring-[#6cb4e4]/50 focus:border-transparent"
                placeholder={t("Planning Period in Months")}
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  loading="lazy"
                  src={time}
                  alt="time icon"
                  className="opacity-70"
                />
              </div>
            </div>

            {/* Livestock or Crops Inputs */}
            {isLivestock ? (
              <>
                {/* Livestock Selection */}
                <div className="relative">
                  <Select
                    placeholder={t("select a livestock")}
                    value={option}
                    className="text-black text-lg"
                    styles={{
                      ...customStyles,
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderColor: "rgba(209, 213, 219, 0.8)",
                        borderRadius: "9999px",
                        paddingLeft: "2.5rem",
                        "&:hover": {
                          borderColor: "rgba(209, 213, 219, 1)",
                        },
                      }),
                    }}
                    onChange={(item) => {
                      handleChanget(item);
                      setSelectedItem(item.value);
                    }}
                    options={livestock}
                  />
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img
                      loading="lazy"
                      src={cropsImg}
                      alt="crops icon"
                      className="opacity-70"
                    />
                  </div>
                </div>

                {/* Breed Type Selection */}
                <div className="relative">
                  <Select
                    placeholder={t("Select a breed type")}
                    value={currentBreed}
                    className="text-black text-lg"
                    styles={{
                      ...customStyles,
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderColor: "rgba(209, 213, 219, 0.8)",
                        borderRadius: "9999px",
                        paddingLeft: "2.5rem",
                        "&:hover": {
                          borderColor: "rgba(209, 213, 219, 1)",
                        },
                      }),
                    }}
                    onChange={(item) => {
                      handleChangeBreed(item);
                      getFormQuestions(item.value, selectedItem);
                    }}
                    options={breedTypes}
                  />
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img
                      loading="lazy"
                      src={cropsImg}
                      alt="crops icon"
                      className="opacity-70"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Crop Selection */}
                <div className="relative">
                  <select
                    onChange={(e) => setSelectedCrop(e.target.value)}
                    value={selectedCrop}
                    className="pl-10 pr-4 py-3 text-lg text-gray-700 border border-gray-200/80 bg-white/90 rounded-full w-full focus:ring-2 focus:ring-[#6cb4e4]/50 focus:border-transparent"
                  >
                    <option value="">{t("select crop")}</option>
                    {allCrops?.map((cropOption) => (
                      <option key={cropOption.name} value={cropOption.value}>
                        {cropOption.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img
                      loading="lazy"
                      src={cropsImg}
                      alt="crops icon"
                      className="opacity-70"
                    />
                  </div>
                </div>

                {/* Water Source Selection */}
                <div className="relative">
                  <select
                    onChange={(e) => setSource(e.target.value)}
                    value={source}
                    className="pl-10 pr-4 py-3 text-lg text-gray-700 border border-gray-200/80 bg-white/90 rounded-full w-full focus:ring-2 focus:ring-[#6cb4e4]/50 focus:border-transparent"
                  >
                    <option value="">{t("select water source")}</option>
                    {waterSource?.map((waterOption) => (
                      <option key={waterOption.name} value={waterOption.value}>
                        {waterOption.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img
                      loading="lazy"
                      src={cropsImg}
                      alt="crops icon"
                      className="opacity-70"
                    />
                  </div>
                </div>
              </>
            )}

            {/* Submit Button */}
            <button
              onClick={getCompositeScore}
              disabled={loading || isProtected}
              className={`w-full py-4 text-lg font-bold rounded-full bg-gradient-to-r from-[#002d62]/90 to-[#7A5A44]/90 text-white/95 shadow-md hover:shadow-lg transition-all ${
                loading ? "opacity-80 cursor-not-allowed" : "hover:opacity-95"
              }`}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white/90"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </div>
              ) : (
                t("submit")
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Right Section: Hero Image */}
      <div className={`hero ${fade ? "fade-in" : "fade-out"} block h-fit`}>
        <img
          loading="lazy"
          src={images[currentImageIndex]}
          alt="Hero"
          className="rounded-lg shadow-xl"
        />
      </div>
    </div>

    {/* Map Popup Modal */}
    {openMapPopup && (
      <div
        id="ModelContainer"
        onClick={handlelosePopUp}
        className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-50 backdrop-blur-sm"
      >
        <div className="relative p-4 m-5 px-7 bg-white w-full md:w-2/3 h-[70vh] flex justify-between flex-col text-center shadow-lg rounded-xl">
          <div
            onClick={() => setOpenMapPopup(false)}
            className="absolute right-3 top-3 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
          <Places
            setIsProtected={setIsProtected}
            address={address}
            setOpenMapPopup={setOpenMapPopup}
            selected={selected}
            setSelected={setSelected}
            setAddress={setAddress}
          />
        </div>
      </div>
    )}
    {openCapPopup === true ? (
      <div
        // style={{ zIndex: "99999" }}
        id="ModelContainer"
        onClick={() => setOpenCapPopup(false)}
        className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm "
      >
        <div className="relative p-2 m-2 md:m-5 md:px-7 bg-white w-[350px]  flex justify-between flex-col text-center shadow-inner border-e-emerald-600 rounded-lg py-5 pt-6">
          <div
            onClick={() => setOpenMapPopup(false)}
            className="absolute right-3 top-3 cursor-pointer"
          >
            {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg> */}
          </div>
          <FormCaptcha />
        </div>
      </div>
    ) : (
      <></>
    )}
    <Modal
      onClose={() => {
        setStep(0);
        type.current = null;
        selectedOption.current = null;
        setSumbit({});
        setOpen(false);
        setOption(null);
      }}
      show={open}
      className="rounded-xl overflow-hidden"
    >
      <Modal.Header className="items-center bg-gradient-to-r from-[#7A5A44]/10 to-[#002d62]/10 border-b border-gray-200 py-4">
        <h3 className="text-xl font-bold text-gray-800">
          {t("Please answer the following questions")}
        </h3>
        <button
          onClick={() => {
            setStep(0);
            type.current = null;
            selectedOption.current = null;
            setSumbit({});
            setOpen(false);
            setOption(null);
          }}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          {/* <FiX className="w-5 h-5" /> */}
        </button>
      </Modal.Header>

      <Modal.Body className="overflow-visible min-h-[200px] p-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#002d62]"></div>
          </div>
        ) : (
          <div className="space-y-6">
            <p className="text-lg font-medium text-gray-700 mb-2">
              {currentQuestion?.question}
            </p>
            <div className="text-sm text-gray-500 mb-4">
              {`${t("Question")} ${currentQuestionIndex + 1} of ${
                questionnaires.length
              }`}
            </div>
            {renderQuestionInput()}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="bg-gray-50 px-6 py-4 border-t border-gray-200">
        <div className="flex justify-between items-center w-full">
          <button
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
            className={`px-6 py-2 rounded-lg font-medium transition-all ${
              currentQuestionIndex === 0
                ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                : "bg-[#002d62] text-white hover:opacity-90"
            }`}
          >
            {t("Previous")}
          </button>

          {isLastQuestion ? (
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className={`px-6 py-2 rounded-lg font-medium transition-all flex items-center ${
                isSubmitting
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-[#7A5A44] text-white hover:opacity-90"
              }`}
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t("Submitting...")}
                </>
              ) : (
                t("Submit")
              )}
            </button>
          ) : (
            <button
              onClick={handleNext}
              className="px-6 py-2 rounded-lg font-medium bg-[#002d62] text-white hover:opacity-90 transition-all"
            >
              {t("Next")}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>

    <Modal
      onClose={() => {
        setOpenModal(false);
      }}
      show={openModal}
    >
      <Modal.Header className="items-center">
        {t("How do you keep your animals")}
      </Modal.Header>
      <Modal.Body className="min-h-[200px]" style={{ overflow: "visible" }}>
        {isLoading ? (
          <>
            {/* <BeatLoader
                  color={"#87CEEB"}
                  // loading={loading}
                  cssOverride={override}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /> */}
            Loading...
          </>
        ) : (
          <div className="space-y-6">
            {questions?.map((question, index) => {
              const choicesObjects = question?.choices?.map((choice) => ({
                label: choice,
                value: choice,
              }));
              if (step === index) {
                type.current = question.category_type;
              }

              return (
                <>
                  {step === index && (
                    <>
                      <div key={index} className="font-regular">
                        {t(
                          "You will get further question based on this answer"
                        )}
                      </div>
                      {/* <div className="font-semibold">
                            <p className="mb-4">{currentQuestion?.question}</p>
                          </div> */}
                      <div className="z-10 mt-3">
                        {choicesObjects.length === 0 ? (
                          <input
                            type="number"
                            className="border border-gray-300 p-2 rounded-md dark:text-black "
                            value={inputValue}
                            onChange={(e) => setinputValue(e.target.value)}
                          />
                        ) : (
                          <Select
                            placeholder={t("Select")}
                            className="dark:text-black"
                            value={
                              (selectedOption.current && {
                                value: selectedOption.current.value,
                                label: selectedOption.current.label,
                              }) ||
                              (sumbmit[type.current] && {
                                value: sumbmit[type.current],
                                label: sumbmit[type.current],
                              })
                            }
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                                color: "black",
                              }),
                            }}
                            options={choicesObjects}
                            onChange={handleChangeFirstQuestion}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="py-3"></Modal.Footer>
    </Modal>
    <ModalSelf isOpen={isModalOpen}>
      <div className="w-[80%] md:w-[60%] h-full fixed  top-0 mx-auto left-[50%] scrollbar-hide translate-x-[-50%] z-[60] overflow-x-hidden overflow-y-auto">
        <div className="flex flex-col bg-white border shadow-sm rounded-xl ">
          <div className="flex justify-between items-center py-3 px-4 border-b ">
            {/* <h3 className="font-bold text-gray-800 ">{t("Full Report")}</h3> */}
            <div>
              {/* Col */}
              <div className="inline-flex gap-x-2 mr-5"></div>
              {/* Col */}
              <button
                onClick={() => setIsModalOpen(false)}
                type="button"
                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm  "
              >
                <span className="sr-only">{t("Close")}</span>
                <svg
                  className="w-3.5 h-3.5"
                  width={8}
                  height={8}
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="p-4 md:h-screen overflow-y-auto">
            {isLivestock ? (
              <PartialReportLivestock
                reportId={reportIdLivestock}
                setIsModalOpen={setIsModalOpen}
                reportData={profitData}
              />
            ) : (
              <PartialReport
                reportId={reportId}
                setIsModalOpen={setIsModalOpen}
                reportData={reportData}
              />
            )}
          </div>
        </div>
      </div>
    </ModalSelf>
  </>
);

};

