import biodiversity from "../../assets/images/biodiversity.jpg";
import climate from "../../assets/images/climate.jpg";
import water from "../../assets/images/water.jpg";
import soil from "../../assets/images/soil.jpg";
import profitablity from "../../assets/images/Profitability.jpg";
import PillerCard from "../PillerCard";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";


const Piller = () => {
  const { t } = useTranslation("home");
  const pillerData = [
    {
      image: biodiversity,
      title: t("biodiversity check"),
      hoverText: t("biodersity heading"),
      hoverHeading: t("biodiversity check"),
    },
    {
      image: climate,
      title: t("climate risk"),
      hoverText: t("climate heading"),
      hoverHeading: t("climate risk"),
    },
    {
      image: soil,
      title: t("soil risk"),
      hoverText: t("soil heading"),
      hoverHeading: t("soil risk"),
    },
    {
      image: water,
      title: t("water risk"),
      hoverText: t("water heading"),
      hoverHeading: t("water risk"),
    },
    {
      image: profitablity,
      title: t("profitablity"),
      hoverText: t("profitablity heading"),
      hoverHeading: t("profitablity"),
    },
  ];
  return (
    <div className="text-center my-[40px] md:my-[100px]">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-[28px] md:text-[50px] font-bold text-[#3B3B3B]"
      >
        {t("climate score pillers")}
      </motion.h2>

      <motion.p
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-[20px] text-[#5A5A5A] mt-4 mb-8 max-w-xl mx-auto"
      >
        {t("we have developed a climate")}
      </motion.p>

      {/* <motion.button
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
        type="button"
        className="text-white mt-6 bg-[#6B8E23] hover:bg-[#557B35] border focus:outline-none focus:ring-4 focus:ring-[#557B35] font-medium rounded-full text-sm px-[55px] py-[14px] text-center mb-5 shadow-md transition-all"
      >
        {t("buy report")}
      </motion.button> */}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 mx-4 mt-10"
      >
        {pillerData.map(({ image, hoverHeading, hoverText, title }, index) => (
          <PillerCard
            key={index}
            title={title}
            image={image}
            hoverHeading={hoverHeading}
            hoverText={hoverText}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default Piller;
