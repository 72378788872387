import { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import baseInstance from "../api";
import { useTranslation } from "react-i18next";
import { FiSend, FiLoader, FiCheckCircle } from "react-icons/fi";

interface ContactFormData {
  requester_name: string;
  requester_email: string;
  requester_phone_number: string;
  message: string;
  request_type?: string;
}

const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  requester_phone_number: yup.string().required("Phone number is required"),
  message: yup
    .string()
    .required("Message is required")
    .min(20, "Message should be at least 20 characters"),
});

export default function ContactUs() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormData>({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation("home");

  const onSubmit = async (data: ContactFormData) => {
    try {
      const payload = { ...data, request_type: "INFO" };

      await baseInstance({
        method: "POST",
        url: "/emails/landing-page/request",
        data: payload,
      });

      reset();
      setSubmitSuccess(true);
      toast.success(t("Form submitted successfully!"));

      // Reset success state after 5 seconds
      setTimeout(() => setSubmitSuccess(false), 5000);
    } catch (error:any) {
      console.error("Error submitting form:", error);
      toast.error(
        error.response?.data?.message ||
          t("An error occurred. Please try again.")
      );
    }
  };

  if (submitSuccess) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-6">
        <div className="max-w-md w-full bg-white p-8 rounded-2xl shadow-lg text-center">
          <FiCheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            {t("Thank You!")}
          </h2>
          <p className="text-gray-600 mb-6">
            {t("We've received your message and will get back to you soon.")}
          </p>
          <button
            onClick={() => setSubmitSuccess(false)}
            className="w-full md:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {t("Send Another Message")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-3">
            {t("Get in Touch")}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {t("Have questions about our API or pricing? We're here to help.")}
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="md:flex">
            <div className="md:w-1/2 bg-gradient-to-r from-[#7A5A44] to-[#002d62] p-8 text-white flex flex-col justify-center">
              <h2 className="text-2xl font-bold mb-4">
                {t("Contact Information")}
              </h2>
              <p className="mb-6">
                {t(
                  "Fill out the form and our team will get back to you within 24 hours."
                )}
              </p>

              <div className="space-y-4">
                <div className="flex items-start">
                  <svg
                    className="w-5 h-5 mt-1 mr-3 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span>info@adapta.earth</span>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 p-8">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {t("Full Name")} *
                    </label>
                    <input
                      id="name"
                      {...register("requester_name")}
                      className={`w-full px-4 py-3 rounded-lg border ${
                        errors.requester_name
                          ? "border-red-500"
                          : "border-gray-300"
                      } transition-all`}
                    />
                    {errors.requester_name && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.requester_name.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {t("Email Address")} *
                    </label>
                    <input
                      id="email"
                      type="email"
                      {...register("requester_email")}
                      className={`w-full px-4 py-3 rounded-lg border ${
                        errors.requester_email
                          ? "border-red-500"
                          : "border-gray-300"
                      } focus:ring-2 focus:border-transparent transition-all`}
                    />
                    {errors.requester_email && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.requester_email.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {t("Phone Number")} *
                    </label>
                    <Controller
                      control={control}
                      name="requester_phone_number"
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          defaultCountry="ke"
                          value={value}
                          onChange={onChange}
                          inputClassName={`w-full px-4 py-3 rounded-lg border ${
                            errors.requester_phone_number
                              ? "border-red-500"
                              : "border-gray-300"
                          } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        />
                      )}
                    />
                    {errors.requester_phone_number && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.requester_phone_number.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {t("Your Message")} *
                    </label>
                    <textarea
                      id="message"
                      {...register("message")}
                      rows={5}
                      className={`w-full px-4 py-3 rounded-lg border ${
                        errors.message ? "border-red-500" : "border-gray-300"
                      } focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all`}
                    />
                    {errors.message && (
                      <p className="mt-1 text-sm text-red-600">
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`flex items-center px-6 py-3 bg-gradient-to-r from-[#7A5A44] to-[#002d62] text-white rounded-lg hover:bg-blue-700 transition-colors ${
                      isSubmitting ? "opacity-80 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting ? (
                      <>
                        <FiLoader className="animate-spin mr-2" />
                        {t("Sending...")}
                      </>
                    ) : (
                      <>
                        <FiSend className="mr-2" />
                        {t("Send Message")}
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
