//@ts-nocheck
import CommonLayout from "../components/layout/CommonLayout";
import contactUsImg from "../assets/images/earth.webp";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import baseInstance from "../api";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  requester_phone_number: yup.string().required("required"),
  message: yup.string().required("Message is required"),
});

export const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation("contact");

  const onSubmit = async (data: any) => {
    data.request_type = "INFO";
    try {
      const response = await baseInstance({
        method: "POST",
        url: "/emails/landing-page/request",
        data,
      });
      const responseData = response.data;
      reset();
      toast.success(responseData.message);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <CommonLayout isNavDark={true}>
        <div className="w-full py-40 text-[#002d62] bg-gradient-to-b from-blue-50 to-white min-h-screen">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-center gap-10 p-4 md:p-0">
            <div className="w-full md:w-1/2 text-center md:text-left space-y-8 pr-5">
              <h2 className="text-xl text-[#002d62] leading-relaxed">
                {/* {t("We thought you'd")}{" "} */}
                {t("For Pricing and API Enquiries, Reach out to Us!")}{" "}
              </h2>

              <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    {...register("requester_name")}
                    placeholder={t("Name")}
                    className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#338FD9] focus:ring-opacity-30"
                  />
                  <input
                    {...register("requester_email")}
                    placeholder={t("Email")}
                    className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                  />
                </div>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Controller
                    control={control}
                    name="requester_phone_number"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <PhoneInput
                        className="w-full"
                        defaultCountry="ke"
                        inputClassName="w-full"
                        name="phone"
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <textarea
                  className="w-full text-slate-600 bg-white border border-[#338FD9] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                  name="message"
                  {...register("message")}
                  id="message"
                  placeholder={t("Your request")}
                  rows="4"
                  required
                ></textarea>
                <button
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  className="inline-block w-full md:w-fit px-6 py-2 rounded-full bg-white text-[#0047AB] font-bold text-xl shadow-lg hover:bg-[338FD9] hover:text-[#338FD9] transition-all duration-300"
                >
                  {t("Submit")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};
