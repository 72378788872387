import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const EnhancedGauge = ({ value }:any) => {
  const riskLevel = value > 70 ? "high" : value > 40 ? "medium" : "low";

  const colorMap = {
    high: { path: "#EF4444", text: "#DC2626" },
    medium: { path: "#F59E0B", text: "#D97706" },
    low: { path: "#10B981", text: "#059669" },
  };

  return (
    <div className="relative w-32 h-30">
      <CircularProgressbar
        value={value || 0}
        text={`${value || 0}%`}
        styles={buildStyles({
          pathColor: colorMap[riskLevel].path,
          textColor: colorMap[riskLevel].text,
          textSize: "10px",
          pathTransitionDuration: 0.5,
          trailColor: "#E5E7EB",
          strokeLinecap: "round",
        })}
      />
      <div className="absolute inset-0 flex items-center justify-center flex-col pt-4 pointer-events-none">
        {/* <span className="text-xs text-gray-500">{title}</span> */}
      </div>
    </div>
  );
};

export default EnhancedGauge;
