//@ts-nocheck

import CommonLayout from "../components/layout/CommonLayout";
import croplever from "../assets/images/croplever.png";
import livestocklever from "../assets/images/livestocklever.png";
import livestockForm from "../assets/images/livestockform.png";
import cropForm from "../assets/images/cropform.png";
import agribot from "../assets/images/agribot.png";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation("help");
  return (
    <div>
      <CommonLayout isNavDark={true}>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">
            {t("ADAPTA Climate Score Guide")}
          </h1>

          {/* Crops Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold text-gray-700 mb-6 border-b pb-2">
              {t("Crops")}
            </h2>
            <ol className="list-decimal list-inside space-y-6 text-gray-600">
              <li className="flex items-start">
                <span className="font-semibold mr-2">1.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Move the lever to Crops:")}
                  </strong>
                  <p className="mt-1">
                    {t("Select the crop option as below.")}
                  </p>
                  <img
                    src={croplever}
                    alt="Crop lever"
                    className="mt-2 rounded-lg shadow-md"
                  />
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">2.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Enter Your Location:")}
                  </strong>
                  <p className="mt-1">
                    {t(
                      "This can be an address or GPS coordinates. If the location is in a protected area, the system will alert you. Otherwise, it will calculate the risk for your selected crop."
                    )}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">3.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Set the Time Period:")}
                  </strong>
                  <p className="mt-1">
                    {t(
                      "Enter the time period (in months) for which you want to calculate the climate risk."
                    )}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">4.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Select the crop of your choice:")}
                  </strong>
                  <p className="mt-1">
                    {" "}
                    {t("Choose the crop you want to assess.")}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">5.</span>
                <div>
                  <strong className="text-gray-800">
                    {" "}
                    {t("Click Submit:")}
                  </strong>
                  <p className="mt-1">
                    {t(
                      "After filling in all details, submit to see the climate risk score."
                    )}
                  </p>
                  <img
                    src={cropForm}
                    alt="Crop Form"
                    className="mt-2 rounded-lg shadow-md"
                  />
                </div>
              </li>
            </ol>

            <div className="mt-8 bg-gray-50 p-6 rounded-lg">
              <h3 className="font-bold text-xl text-gray-700 mb-4">
                {t("What Happens Next?")}
              </h3>
              <p className="text-gray-600 mb-4">
                {t(
                  "You will see a Composite Climate Score that ranges from 0 (no risk) to 100 (extremely risky). A score of 75 or higher indicates serious difficulty in growing the crop in that area."
                )}
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-600">
                <li>
                  <strong className="text-gray-800">
                    {" "}
                    {t("Climate Risk:")}
                  </strong>{" "}
                  {t(
                    "Looks at factors like drought, precipitation, and temperature."
                  )}
                </li>
                <li>
                  <strong className="text-gray-800">{t("Water Risk:")}</strong>{" "}
                  {t(
                    "Factors include groundwater availability, water erosion, and water stress."
                  )}
                </li>
                <li>
                  <strong className="text-gray-800">{t("Soil Risk:")}</strong>{" "}
                  {t(
                    "Considers topsoil fertility, soil pH, and nutrient capacity."
                  )}
                </li>
              </ul>
            </div>
          </section>

          {/* Livestock Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold text-gray-700 mb-6 border-b pb-2">
              {t("Livestock")}
            </h2>
            <ol className="list-decimal list-inside space-y-6 text-gray-600">
              <li className="flex items-start">
                <span className="font-semibold mr-2">1.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Move the lever to Livestock:")}
                  </strong>
                  <p className="mt-1">
                    {t("Select the livestock option as shown below.")}
                  </p>
                  <img
                    src={livestocklever}
                    alt="Livestock lever"
                    className="mt-2 rounded-lg shadow-md"
                  />
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">2.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Enter Your Location:")}
                  </strong>
                  <p className="mt-1">
                    {t(
                      "Provide an address or geo-coordinates. If the location is protected, a warning will appear."
                    )}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">3.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Enter Time Period:")}
                  </strong>
                  <p className="mt-1">
                    {t("Set the time in months for climate risk calculation.")}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">4.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Select the livestock:")}
                  </strong>
                  <p className="mt-1">
                    {t("Choose the livestock for assessment.")}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">5.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Respond to Livestock-Specific Questions:")}
                  </strong>
                  <p className="mt-1">
                    {t("These questions help assess profitability and risk.")}
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="font-semibold mr-2">6.</span>
                <div>
                  <strong className="text-gray-800">
                    {t("Click Submit:")}
                  </strong>
                  <p className="mt-1">
                    {t(
                      "Submit to get a composite risk score based on climate, water, and profitability risks."
                    )}
                  </p>
                  <img
                    src={livestockForm}
                    alt="Livestock Form"
                    className="mt-2 rounded-lg shadow-md"
                  />
                </div>
              </li>
            </ol>

            <div className="mt-8 bg-gray-50 p-6 rounded-lg">
              <h3 className="font-bold text-xl text-gray-700 mb-4">
                {t("Biodiversity Check")}
              </h3>
              <p className="text-gray-600">
                {t(
                  "If the location is not protected, the system will proceed to calculate the climate, water, and profitability risks for livestock. You will receive a composite score for your chosen livestock."
                )}
              </p>
            </div>
          </section>

          {/* Agribot Section */}
          <section className="mb-12 bg-green-50 p-6 rounded-lg">
            <h2 className="text-3xl font-semibold text-gray-700 mb-4">
              {t("Agribot Assistance")}
            </h2>
            <p className="text-gray-600">
              {t("ADAPTA has developed an AI agent named")}{" "}
              <strong className="text-gray-800">Agribot</strong>{" "}
              {t(
                "that can help answer questions about your crops or livestock. Agribot can also help you interpret scores or provide answers to general agriculture-related questions."
              )}
            </p>
            <img src={agribot} alt="" />
          </section>

          {/* Contact Section */}
          <section className="mt-12 bg-blue-50 p-6 rounded-lg">
            <h2 className="text-3xl font-semibold text-gray-700 mb-4">
              {t("Need More Help?")}
            </h2>
            <p className="text-gray-600">
              {t("For any other queries, contact us at")}{" "}
              <a
                href="mailto:info@adapta.earth"
                className="text-blue-600 hover:text-blue-800 underline transition duration-300"
              >
                info@adapta.earth
              </a>
              .
            </p>
          </section>
        </div>
      </CommonLayout>
    </div>
  );
};

export default Help;
