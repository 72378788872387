import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import { useTranslation } from "react-i18next";
import "../../i18n"; // Import the i18n configuration
import LanguageSwitcher from "../LanguageSwitcher";
export default function Navbar({}: any) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [selectedLanguage, setSelectedLanguage] = useState("en");
  const redirectUrl = import.meta.env.VITE_REDIRECT;
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const { t, i18n } = useTranslation("home");

  const locale = localStorage.getItem("lang");
  const redirectUrlWithLocale = `${redirectUrl}?ui_locales=${locale}`;

  const handleLanguageChange = (lang: any) => {
    console.log(`Language changed to: ${lang}`);
    // setLanguage(lang);
    i18n?.changeLanguage(lang);
  };

  return (
    <div className="navbar-b w-full overflow-x-hidden">
      <nav className="relative flex items-center w-full min-w-screen px-4  bg-gradient-to-r from-[#7A5A44] to-[#002d62] shadow-lg">
        {/* Mobile Navigation */}
        <ul className="flex py-4 w-full h-16 items-center justify-between md:hidden">
          <li>
            <button
              onClick={toggleMenu}
              className="text-3xl transition-transform hover:scale-110 text-white"
            >
              ☰
            </button>
          </li>
          <li className="flex-grow text-center">
            <button onClick={() => navigate("/")}>
              <img src={logo} alt="Logo" className="h-8 mx-auto" />
            </button>
          </li>
          <li>
            <button
              onClick={() => window.open(redirectUrlWithLocale, "_blank")}
              className="text-white bg-transparent border border-white hover:bg-white hover:text-[#002d62] transition-all rounded-full text-xs px-3 py-1"
            >
              {t("Sign in")}
            </button>
          </li>
        </ul>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex w-full h-20 items-center justify-between">
          <li>
            <button onClick={() => navigate("/")}>
              <img src={logo} alt="Logo" className="h-8 mx-auto max-w-full" />
            </button>
          </li>
          <li className="flex space-x-6">
            <NavLink
              to={`/`}
              className={({ isActive }) =>
                `px-4 py-2 text-medium font-medium transition-colors no-underline ${
                  isActive
                    ? "text-[#a0d8ef]"
                    : "text-white hover:text-[#a0d8ef]"
                }`
              }
            >
              {t("Home")}
            </NavLink>
            {["Premium", "About Us", "Contact Us", "Help"].map((item) => (
              <NavLink
                key={item}
                to={`/${item.toLowerCase().replace(" ", "-")}`}
                className={({ isActive }) =>
                  `px-4 py-2 text-medium font-medium transition-colors no-underline ${
                    isActive
                      ? "text-[#a0d8ef]"
                      : "text-white hover:text-[#a0d8ef]"
                  }`
                }
              >
                {t(item)}
              </NavLink>
            ))}
          </li>

          <li className="flex items-center gap-4">
            <LanguageSwitcher onLanguageChange={handleLanguageChange} />
            <button
              onClick={() => window.open(redirectUrlWithLocale, "_blank")}
              className="text-white bg-transparent border border-white hover:bg-white hover:text-[#002d62] transition-all rounded-full text-sm px-5 py-2"
            >
              {t("Sign in")}
            </button>
          </li>
        </ul>
      </nav>

      {/* Mobile Menu */}
      <div
        className={`md:hidden absolute top-16 left-0 right-0 z-20 backdrop-blur-lg bg-white/80 shadow-md rounded-2xl transition-all duration-300 ${
          isMenuOpen
            ? "max-h-screen opacity-100"
            : "max-h-0 opacity-0 overflow-hidden"
        }`}
      >
        <div className="p-6 flex flex-col items-start gap-4">
          {["About Us", "Premium", "Contact Us", "Help"].map((item) => (
            <NavLink
              key={item}
              to={`/${item.toLowerCase().replace(" ", "-")}`}
              onClick={toggleMenu}
              className={({ isActive }) =>
                `block text-sm font-medium transition-colors ${
                  isActive
                    ? "text-[#80D467]"
                    : "text-black hover:text-[#80D467]"
                }`
              }
            >
              {t(item)}
            </NavLink>
          ))}
          <LanguageSwitcher onLanguageChange={handleLanguageChange} />
        </div>
      </div>
    </div>
  );
}
