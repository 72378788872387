//@ts-nocheck
import "./App.css";
import { Hero } from "./components/Hero";

import HeroImg from "./assets/images/background_sky.png";
import Piller from "./components/layout/Piller";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Pricing from "./components/Pricing";
import ContactUs from "./components/Contact";
import LegalDisclamer from "./components/LegalDisclamer";
import Timeline from "./components/Timeline";
import { useState } from "react";
import Promo from "./components/Promo";
import PromoSlide from "./components/PromoSlide";
import PromoBottom from "./components/PromoBottom";
import { LanguageProvider } from "./context/Language";


function App() {
  const [openMapPopup, setOpenMapPopup] = useState(false);
  return (
    <>
      <div className=" bg-cover	bg-no-repeat	 bg-center hero-bg-img ">
        {/* <div className=" bg-cover	bg-no-repeat	 bg-center "> */}
        {/* <Promo /> */}

        {/* <PromoSlide setOpenMapPopup={setOpenMapPopup} /> */}
        {/* <PromoBottom /> */}
        <div className="">
          <Navbar />
          <Hero openMapPopup={openMapPopup} setOpenMapPopup={setOpenMapPopup} />
        </div>
      </div>
      <div className="container px-[30px]">
        <Piller />
      </div>
      <div className="my-[100px] ">
        <Timeline />
      </div>

      {/* <div className="bg-[#1E1E1E] text-white">
        <div className="md:container ">
          <div className="py-16" id="prices">
            <Pricing setOpenMapPopup={setOpenMapPopup} />
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <ContactUs />
        </div>
        <div className="col-md-6">
          <div className="bg-gradient-to-br from-blue-50 to-gray-100 p-4">
            <LegalDisclamer />
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
export default App;
