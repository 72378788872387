import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import baseInstance from "../api";
import { usePrice } from "../context/Price";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import CommonLayout from "../components/layout/CommonLayout";
import { checkEmailQuota } from "../helpers/api";
import { Spinner } from "flowbite-react";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  full_name: yup.string().required("Full name is required"),
  email_is_checked: yup.boolean(),
});
const ROUTE_PATHS = {
  ECO_CROP: "/v1/b2c/free-trial/eco-crop/success/full-report",
  LIVESTOCK: "/v1/b2c/free-trial/livestock/success/full-report",
};

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [checkingQuota, setCheckingQuota] = useState(false);
 const [emailQuota, setEmailQuota] = useState<{
   remaining_quota: number;
   free_quota: number;
 } | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { setPrice }: any = usePrice();
  const { t } = useTranslation("home");

  const { reportData } = location.state || {};
  if (!reportData) {
    navigate("/partial-report");
    return null;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

    const email = watch("email");


  // Quota check effect
  useEffect(() => {
    const checkQuota = async () => {
      if (email && email.includes("@")) {
        setCheckingQuota(true);
        try {
          const quotaData = await checkEmailQuota(email);
          setEmailQuota({
            remaining_quota: quotaData.remaining_quota,
            free_quota: quotaData.free_quota,
          });
        } catch (error) {
          setEmailQuota({
            remaining_quota: 5, 
            free_quota: 5,
          });
        } finally {
          setCheckingQuota(false);
        }
      }
    };

    const timer = setTimeout(checkQuota, 500); 
    return () => clearTimeout(timer);
  }, [email])


 const onSubmit = async (data: any) => {
   if (emailQuota && emailQuota.remaining_quota <= 0) {
      const firstName = data.full_name.split(" ")[0];

     navigate("/premium", {
       state: {
         message: t(
           "You've used all your free reports ({{used}}/{{total}}). Upgrade to continue accessing insights.",
           {
             used: emailQuota.free_quota,
             total: emailQuota.free_quota,
           }
         ),
         email: data.email,
         firstName: firstName,
       },
     });
     return;
   }

   setLoading(true);
   try {
     const business_type = reportData.crop ? "ecocrop" : "livestock";
     const { report_id } = reportData;
     const payload = { ...data, business_type, report_id };

     const res = await baseInstance.post(
       "/v1/b2c/free-trial/user/register",
       payload
     );

     setPrice(res?.data);

     const successRoute =
       business_type === "ecocrop"
         ? ROUTE_PATHS.ECO_CROP
         : ROUTE_PATHS.LIVESTOCK;

     navigate(`${successRoute}/${payload.report_id}`, {
       state: {
         reportData: {
           ...reportData,
           email: data.email,
           full_name: data.full_name,
         },
       },
     });
   } catch (error) {
     console.error("Error:", error);
     Swal.fire({
       icon: "error",
       title: t("Oops"),
       text: t("Something went wrong. Please try again."),
     });
   } finally {
     setLoading(false);
   }
 };

  return (
    <CommonLayout isNavDark={true}>
      <div
        className="min-h-screen flex flex-col justify-start py-12 pt-8 sm:px-6 lg:px-8 transition-all duration-2000 ease-in-out bg-gradient-to-b from-blue-50 to-white"
        // style={{
        //   background: gradients[gradientIndex],
        //   backgroundSize: "300% 300%",
        //   animation: "gradientShift 15s ease infinite",
        // }}
      >
        <style>{`
          @keyframes gradientFlow {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          .gradient-bg {
            animation: gradientFlow 12s ease infinite;
          }
        `}</style>
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <h2 className="mt-6 text-center text-2xl font-bold text-black animate-fade-in">
            {t("You're Seconds Away from Your Report...")}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg animate-slide-up">
          <div className="bg-white/90 backdrop-blur-sm py-10 px-8 shadow-xl rounded-lg sm:px-12 border border-gray-100 min-h-[400px] flex flex-col justify-center">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              {/* Email Input */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Email address")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register("email")}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                  {checkingQuota && (
                    <span className="absolute right-3 top-2.5">
                      <Spinner size="md" />
                    </span>
                  )}
                </div>

                {emailQuota && (
                  <div
                    className={`text-sm mt-1 ${
                      emailQuota.remaining_quota > 0
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    Reports remaining: {emailQuota.remaining_quota}/
                    {emailQuota.free_quota}
                  </div>
                )}
              </div>

              {/* Full Name Input */}
              <div>
                <label
                  htmlFor="full_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("Name")}
                </label>
                <div className="mt-1">
                  <input
                    id="full_name"
                    type="text"
                    autoComplete="name"
                    required
                    {...register("full_name")}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#002d62] sm:text-sm"
                  />
                </div>
                {errors.full_name && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.full_name.message}
                  </p>
                )}
              </div>
              <div className="flex items-center">
                <input
                  id="email_is_checked"
                  type="checkbox"
                  {...register("email_is_checked")}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="email_is_checked"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {t("Want to receive promotional content")}
                </label>
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                    bg-gradient-to-r from-[#7A5A44] to-[#002d62] 
                    ${
                      loading
                        ? "cursor-not-allowed opacity-75"
                        : "hover:from-[#002d62] hover:to-[#7A5A44]"
                    } 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#002d62] transition-all duration-200`}
                  disabled={loading}
                >
                  {loading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                      ></path>
                    </svg>
                  ) : (
                    t("Submit")
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default RegisterForm;
