import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";

// Define the type for language codes
type LanguageCode = "en" | "de" | "es" | "fr" | "pt" | "ru";

// Define the return type of useLanguage hook
interface LanguageContextType {
  selectedLanguage: LanguageCode;
  // Add other properties if your hook returns more values
}

const LegalDisclaimer = () => {
  const { t } = useTranslation("home");
  const { selectedLanguage } = useLanguage() as LanguageContextType;

  const legalDocIds: Record<LanguageCode, string> = {
    de: "30143821",
    en: "12212628",
    es: "21525546",
    fr: "83018684",
    pt: "93887534",
    ru: "73617794",
  };

  const handleReadMore = () => {
    window.open(
      `https://www.iubenda.com/terms-and-conditions/${
        legalDocIds[selectedLanguage] || legalDocIds.en
      }`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <section className="relative py-16 px-6 sm:px-8 lg:px-12 bg-white rounded-2xl shadow-lg max-w-5xl mx-auto my-12 border border-gray-200/70">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-blue-500 to-blue-300 rounded-t-2xl"></div>

      <div className="text-center space-y-6">
        <h2 className="text-3xl md:text-4xl lg:text-[2.8rem] font-bold text-gray-800 tracking-tight">
          {t("LEGAL DISCLAIMER")}
        </h2>

        <div className="prose prose-lg mx-auto text-gray-600/90">
          <p className="leading-relaxed text-lg md:text-xl">
            {t(
              "Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required. To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application."
            )}
          </p>

          <button
            onClick={handleReadMore}
            className="mt-8 px-6 py-3 inline-flex items-center rounded-lg bg-blue-50 hover:bg-blue-100 text-blue-600 hover:text-blue-800 font-medium transition-all duration-300 group"
            aria-label={t("Read full terms and conditions")}
          >
            {t("Read full terms and conditions")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2 transition-transform duration-300 group-hover:translate-x-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Bottom decorative element */}
      <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-blue-300 to-blue-500 rounded-b-2xl opacity-50"></div>
    </section>
  );
};

export default LegalDisclaimer;
