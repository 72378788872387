import CommonLayout from "../components/layout/CommonLayout";
import { CheckCircle, Zap, BarChart2, Video } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Premium() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const greeting = state?.firstName
    ? `Hi ${state.firstName}, ${t(
        "we're thrilled you're finding value in our reports! Upgrade to Premium for unlimited insights and advanced features."
      )}`
    : t(
        "We're thrilled you're finding value in our reports! Upgrade to Premium for unlimited insights and advanced features."
      );

  const handleUpgradeClick = () => {
    navigate("/premium-register", {
      state: {
        email: state?.email,
        firstName: state?.firstName,
      },
    });
  };
  const handleDemo = () => {
    navigate("/tutorial");
  };

  const premiumPlans = [
    {
      name: "Basic",
      price: "$9.99",
      period: "/month",
      features: ["20 reports per month", "Email support", "Advanced analytics"],
      cta: "Get Started",
    },
    {
      name: "Pro",
      price: "$24.99",
      period: "/month",
      popular: true,
      features: ["100 reports per month", "Advanced analytics", "Priority support"],
      cta: "Most Popular",
    },
    {
      name: "Enterprise",
      price: "Custom",
      period: "",
      features: ["All Pro features", "API access", "Custom integrations"],
      cta: "Contact Sales",
    },
  ];

  return (
    <CommonLayout isNavDark={true}>
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="max-w-4xl mx-auto text-center mb-16">
          {state?.message && (
            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8 rounded">
              <p>{state.message}</p>
            </div>
          )}

          <p className="text-2xl font-semibold text-gray-900 mb-4">
            {greeting}
          </p>

          <div className="flex justify-center space-x-4">
            <button
              onClick={handleUpgradeClick}
              className="bg-gradient-to-r from-[#7A5A44] to-[#002d62] hover:from-[#002d62] hover:to-[#7A5A44] text-white px-6 py-3 rounded-lg font-medium transition-all duration-300 flex items-center justify-center"
            >
              <Zap className="mr-2 h-5 w-5" />
              {t("Upgrade Now")}
            </button>
            <button
              className="px-6 py-3 border border-gray-300 hover:bg-gray-50 rounded-lg font-medium transition-colors flex items-center justify-center"
              onClick={handleDemo}
            >
              <Video className="mr-2 h-5 w-5" />
              {t("Watch Demo")}
            </button>
          </div>
        </div>

        {/* Features Grid */}
        <div className="max-w-7xl mx-auto mb-16">
          <h2 className="text-2xl font-bold text-center mb-12 text-gray-900">
            {t("Premium Features")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <BarChart2 className="h-8 w-8 text-blue-600" />,
                title: t("Advanced Analytics"),
                description: t(
                  "Deeper insights with predictive modeling and trend analysis"
                ),
              },
              {
                icon: <CheckCircle className="h-8 w-8 text-blue-600" />,
                title: t("Unlimited Reports"),
                description: t(
                  "With the Custom Enterprise plan, generate as many reports as you need without restrictions."
                ),
              },
              {
                icon: <Video className="h-8 w-8 text-blue-600" />,
                title: t("Video Tutorials"),
                description: t("Exclusive access to premium training content"),
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex items-center mb-4">
                  {feature.icon}
                  <h3 className="ml-3 text-lg font-medium text-gray-900">
                    {feature.title}
                  </h3>
                </div>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Pricing Plans */}
        <div className="max-w-7xl mx-auto mb-16">
          <h2 className="text-2xl font-bold text-center mb-12 text-gray-900">
            {t("Choose Your Plan")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {premiumPlans.map((plan, index) => (
              <div
                key={index}
                className={`relative rounded-xl shadow-sm overflow-hidden ${
                  plan.popular
                    ? "ring-2 ring-blue-500"
                    : "border border-gray-200"
                }`}
              >
                {plan.popular && (
                  <div className="absolute top-0 right-0 bg-blue-500 text-white text-xs font-bold px-3 py-1 transform translate-x-2 -translate-y-2 rotate-12">
                    {t("Popular")}
                  </div>
                )}
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-1">
                    {plan.name}
                  </h3>
                  <div className="flex items-baseline mb-4">
                    <span className="text-3xl font-bold text-gray-900">
                      {plan.price}
                    </span>
                    <span className="ml-1 text-gray-600">{plan.period}</span>
                  </div>
                  <ul className="space-y-3 mb-6">
                    {plan.features.map((feature, i) => (
                      <li key={i} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={handleUpgradeClick}
                    className={`w-full px-4 py-2 rounded-md text-white font-medium ${
                      plan.popular
                        ? "bg-gradient-to-r from-[#7A5A44] to-[#002d62] hover:from-[#002d62] hover:to-[#7A5A44]"
                        : "bg-gray-800 hover:bg-gray-700"
                    } transition-all duration-300`}
                  >
                    {plan.cta}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="max-w-4xl mx-auto bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-xl p-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">
            {t("Ready to Transform Your Farm Management?")}
          </h2>
          <p className="text-blue-100 mb-6">
            {t(
              "Join Other ADAPTA farmers and bankers who are already benefiting from our Premium features."
            )}
          </p>
          <button
            onClick={handleUpgradeClick}
            className="bg-white text-blue-600 hover:bg-blue-50 px-8 py-3 text-lg font-bold rounded-lg flex items-center justify-center mx-auto"
          >
            <Zap className="mr-2 h-5 w-5" />
            {t("Upgrade Now")}
          </button>
        </div>
      </div>
    </CommonLayout>
  );
}

export default Premium;
