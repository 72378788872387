//@ts-nocheck
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";

import GaugePdf from "./GaugePdf";
import logo from "../assets/images/logo.png";
import { useLoadScript } from "@react-google-maps/api";
import { Map, MapForReport } from "./PlacesAutocomplete";
import { useTranslation } from "react-i18next";
import { RiskItem } from "./RiskItem";
import {
  getHighestRiskCategory,
  getHighestRiskValue,
  getStrongestValue,
  getStrongestCategory,
  generateTeaserText,
  calculateAdaptationPotential,
  calculateFinancialImpact,
} from "../helpers/insights";
import EnhancedGauge from "./GaugeStyles";

//@ts-ignore
const climate_indices = ["Drought", "Precipitation", "Temperature"];
const PartialReport = ({ reportData, reportId }: any) => {
  const { t } = useTranslation("about");
  const navigate = useNavigate();
  // const oid = localStorage.getItem("oid");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });
  const redirectToCheckout = () => {
    // const url = `/verify?reportId=${reportId}`;
    navigate("/register", { state: { reportData } });
  };
  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Difference in milliseconds
    const differenceInTime = end - start;

    // Convert time difference to days, months, and years
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInMonths = differenceInDays / 30.44; // Approximate months
    const differenceInYears = differenceInMonths / 12;

    return {
      days: Math.floor(differenceInDays),
      months: Math.floor(differenceInMonths),
      years: differenceInYears,
    };
  };

  const { months } = calculateDateDifference(
    reportData.startDate,
    reportData.endDate
  );
const hasMissingData = (reportData) => {
  const climateMissing = Object.values(reportData?.climate_scores || {}).some(
    (v) => v === -1
  );
  const waterMissing = Object.values(reportData?.water_scores || {}).some(
    (v) => v === -1
  );
  const soilMissing = Object.values(reportData?.soil_scores || {}).some(
    (v) => v === -1
  );

  return {
    anyMissing: climateMissing || waterMissing || soilMissing,
    climateMissing,
    waterMissing,
    soilMissing,
  };
};

const missingData = hasMissingData(reportData);

  return (
    <>
      <div
        id="printablediv"
        className="max-w-6xl mx-auto p-4 md:p-8 bg-white rounded-xl shadow-sm"
      >
        {/* Header */}
        <div className="text-center mb-8">
          {/* <img alt="Logo" className="w-40 mx-auto mb-4" src={logo} /> */}
          <h1 className="text-2xl font-bold text-gray-800">
            Your Eco-Crop Risk Assessment
          </h1>
          <p className="text-gray-600 mt-2">
            Partial analysis for {reportData?.crop || "your crop"}
          </p>
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-10">
          {/* Map Section */}
          <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-xs">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              {t("Location Overview")}
            </h3>
            <div className="h-64 md:h-80 bg-gray-50 rounded-lg overflow-hidden">
              {isLoaded ? (
                <MapForReport
                  lat={reportData?.latitude}
                  lng={reportData?.longitude}
                />
              ) : (
                <div className="h-full flex items-center justify-center">
                  <div className="animate-pulse text-gray-400">
                    Loading map...
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Risk Score Section */}
          <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-xs flex flex-col">
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                {/* Dynamic icon based on risk level */}
                {reportData?.composite_total_risk > 50 ? (
                  <svg
                    className="w-5 h-5 mr-2 text-red-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-5 h-5 mr-2 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
                {t("Composite Risk Score")}
              </h3>

              <div className="flex justify-center mb-6">
                <ProgressBar
                  isBig={true}
                  progress={Math.floor(reportData?.composite_total_risk || 0)}
                  width="100%"
                  color={
                    reportData?.composite_total_risk > 50
                      ? "bg-red-500"
                      : "bg-green-500"
                  }
                />
              </div>

              {missingData.anyMissing && (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md mb-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        Incomplete Data Warning
                      </h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>
                          {missingData.climateMissing &&
                            "Climate data unavailable. "}
                          {missingData.waterMissing &&
                            "Water data unavailable. "}
                          {missingData.soilMissing && "Soil data unavailable. "}
                          For accurate results, try moving the pin to a
                          different location.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Optional risk level text */}
              <p className="text-center text-sm text-gray-600">
                {reportData?.composite_total_risk > 70
                  ? "High risk - Immediate attention recommended"
                  : reportData?.composite_total_risk > 50
                  ? "Moderate risk - Consider improvements"
                  : "Low risk - Good condition"}
              </p>
            </div>

            {/* Summary Card */}
            <div className="bg-[#FAFAFA] rounded-lg p-4 border border-gray-100">
              <h4 className="text-md font-semibold text-gray-800 mb-3">
                {t("Quick Summary")}
              </h4>
              <div className="space-y-3">
                <div className="flex justify-between items-center py-2 border-b border-gray-100">
                  <span className="text-sm text-gray-600">
                    {t("Planning Time in months")}
                  </span>
                  <span className="font-medium">{months + 1}</span>
                </div>
                <div className="flex justify-between items-center py-2 border-b border-gray-100">
                  <span className="text-sm text-gray-600">{t("Crop")}</span>
                  <span className="font-medium">{reportData?.crop}</span>
                </div>
                <div className="flex justify-between items-center py-2">
                  <span className="text-sm text-gray-600">
                    {t("Biodiversity")}
                  </span>
                  <span className="text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 mb-10">
          {/* Key Insights Teaser */}
          <div className="bg-white rounded-xl p-6 border border-gray-200 shadow-xs">
            <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-[#002d62]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Your Key Risk Insights
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {/* Most Critical Risk */}
              <div className="bg-[#FFF5F5] p-4 rounded-lg border-l-4 border-red-500">
                <h4 className="font-semibold text-gray-800 flex items-center">
                  <svg
                    className="w-4 h-4 mr-1 text-red-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  Highest Risk
                </h4>
                <p className="text-gray-600 mt-1 text-sm">
                  {getHighestRiskCategory(reportData)} at{" "}
                  {getHighestRiskValue(reportData)}%
                </p>
              </div>

              {/* Most Positive Aspect */}
              <div className="bg-[#F0FFF4] p-4 rounded-lg border-l-4 border-green-500">
                <h4 className="font-semibold text-gray-800 flex items-center">
                  <svg
                    className="w-4 h-4 mr-1 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Strongest Area
                </h4>
                {hasMissingData(reportData).anyMissing ? (
                  <div className="flex items-start mt-1">
                    <svg
                      className="w-4 h-4 mt-0.5 mr-1 text-yellow-500 flex-shrink-0"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                    <p className="text-sm text-yellow-700">
                      Change pin to see strongest area
                    </p>
                  </div>
                ) : (
                  <p className="text-gray-600 mt-1 text-sm">
                    {getStrongestCategory(reportData)} at{" "}
                    {getStrongestValue(reportData)}%
                  </p>
                )}
              </div>

              {/* Climate Impact */}
              <div className="bg-[#FFFAF0] p-4 rounded-lg border-l-4 border-amber-500">
                <h4 className="font-semibold text-gray-800 flex items-center">
                  <svg
                    className="w-4 h-4 mr-1 text-amber-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Financial Impact
                </h4>
                <p className="text-gray-600 mt-1 text-sm">
                  {calculateFinancialImpact(reportData)}% potential savings
                </p>
              </div>
            </div>

            <div className="bg-[#FAFAFA] p-4 rounded-lg border border-gray-200">
              <p className="text-gray-600 text-sm mb-3">
                <span className="font-medium text-gray-800">
                  Our analysis shows:
                </span>{" "}
                {generateTeaserText(reportData)}
              </p>
              <button
                onClick={redirectToCheckout}
                className="w-full md:w-auto px-6 py-2 bg-gradient-to-r from-[#002d62] to-[#7A5A44] text-white rounded-md hover:opacity-90 transition-all text-sm font-medium"
              >
                Get Free Report →
              </button>
            </div>
          </div>

          {/* Original Risk Sections Continue Below */}
          {/* <div className="bg-[#F9F9F9] rounded-xl p-6 border border-gray-100">
          </div> */}
        </div>

        <div className="space-y-6 mb-10">
          {/* Highest Risk Category Section */}
          <div className="bg-[#F9F9F9] rounded-xl p-6 border border-gray-100">
            <div className="flex items-center mb-4">
              <div className="bg-red-100 p-2 rounded-full mr-3">
                <svg
                  className="w-6 h-6 text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                Highest Risk Category: {getHighestRiskCategory(reportData)}
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="w-32 h-32 shrink-0">
                <EnhancedGauge
                  value={getHighestRiskValue(reportData)}
                  title={getHighestRiskCategory(reportData)}
                />
              </div>
              {(() => {
                const highestRiskCategory =
                  getHighestRiskCategory(reportData).toLowerCase();

                // Get all risk components for the highest risk category
                const riskComponents = [];

                if (highestRiskCategory.includes("climate")) {
                  riskComponents.push(
                    {
                      key: "drought_risk",
                      value: reportData?.climate_scores?.drought_risk,
                      label: "Drought Risk",
                    },
                    {
                      key: "precipitation_risk",
                      value: reportData?.climate_scores?.precipitation_risk,
                      label: "Precipitation Risk",
                    }, 
                    {
                      key: "temperature_risk",
                      value: reportData?.climate_scores?.temperature_risk,
                      label: "Temperature Risk",
                    }
                  );
                } else if (highestRiskCategory.includes("water")) {
                  riskComponents.push(
                    {
                      key: "ground_water",
                      value: reportData?.water_scores?.ground_water_risk,
                      label: "Ground Water Risk",
                    },
                    {
                      key: "water_erosion",
                      value: reportData?.water_scores?.rainfall_erosivity_risk,
                      label: "Water Erosion",
                    },
                    {
                      key: "water_stress",
                      value: reportData?.water_scores?.location_aquaduct_risk,
                      label: "Water Stress",
                    }
                  );
                } else if (highestRiskCategory.includes("soil")) {
                  riskComponents.push(
                    {
                      key: "topsoil_fertility",
                      value: reportData?.soil_scores?.soil_organic_carbon_risk,
                      label: "Top Soil Fertility",
                    },
                    {
                      key: "ph_risk",
                      value: reportData?.soil_scores?.soil_ph_risk,
                      label: "Soil PH Risk",
                    },
                    {
                      key: "soil_organic_carbon_risk",
                      value: reportData?.soil_scores?.soil_organic_carbon_risk,
                      label: "Nutrient Capacity",
                    }
                  );
                } else {
                  riskComponents.push(
                    {
                      key: "component_1",
                      value: reportData?.composite_total_risk * 0.7,
                      label: "Primary Factor",
                    },
                    {
                      key: "component_2",
                      value: reportData?.composite_total_risk * 0.5,
                      label: "Secondary Factor",
                    },
                    {
                      key: "component_3",
                      value: reportData?.composite_total_risk * 0.3,
                      label: "Tertiary Factor",
                    }
                  );
                }

                return riskComponents
                  .sort((a, b) => b.value - a.value)
                  .slice(0, 3)
                  .map(({ key, value, label }) => (
                    <RiskItem
                      key={key}
                      title={`${label} Risk`}
                      progress={Math.round(value)}
                      color={
                        label.includes("Drought")
                          ? "bg-orange-500"
                          : label.includes("Precipitation")
                          ? "bg-blue-500"
                          : label.includes("Temperature")
                          ? "bg-red-500"
                          : label.includes("Erosion")
                          ? "bg-yellow-500"
                          : label.includes("Fertility")
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }
                    />
                  ));
              })()}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-gradient-to-r from-[#002d62] to-[#7A5A44] rounded-xl p-8 text-center text-white mb-10 relative overflow-hidden">
          <div className="absolute inset-0 opacity-10 bg-[url('https://example.com/grid-pattern.png')]"></div>
          <h3 className="text-2xl font-bold mb-3 relative z-10">
            Get Your Full Detailed Report for Free
          </h3>
          <p className="mb-6 text-blue-100 max-w-2xl mx-auto relative z-10">
            Unlock all climate adaptation suggestions, detailed risk breakdowns,
            and personalized recommendations.
          </p>
          <button
            onClick={redirectToCheckout}
            className="relative z-10 px-8 py-4 bg-white text-[#002d62] font-bold rounded-lg hover:bg-gray-100 transition-all shadow-md"
          >
            {t("Get Full Report For Free")}
          </button>
        </div>

        {/* Preview Content */}
        <div className="mb-10">
          <div className="blur-sm bg-gradient-to-b from-white to-gray-100 p-6 rounded-lg border border-gray-200"></div>
        </div>

        {/* Footer */}
        <div className="text-center border-t border-gray-200 pt-8">
          <h4 className="text-lg font-semibold text-gray-800 mb-2">
            {t("Thank you!")}
          </h4>
          <p className="text-gray-600 mb-4">
            {t("Have questions about this report? Contact our support team:")}
          </p>
          <a
            href="mailto:info@adapta.earth"
            className="text-blue-600 font-medium hover:underline"
          >
            info@adapta.earth
          </a>
          <p className="mt-6 text-sm text-gray-500">
            © {new Date().getFullYear()} ADAPTA. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default PartialReport;
