import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <footer className="bg-[#002d62]/90 text-white">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="flex flex-col items-center">
          <div className="flex flex-wrap justify-center gap-12 md:gap-24">
            <div className="text-center">
              {/* <h2 className="mb-4 text-sm font-semibold uppercase">
                {t("Quick Links")}
              </h2> */}
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="hover:underline"
                  >
                    {t("Contact Us")}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate("/about-us")}
                    className="hover:underline"
                  >
                    {t("About Us")}
                  </button>
                </li>
              </ul>
            </div>

            <div className="text-center">
              {/* <h2 className="mb-4 text-sm font-semibold uppercase">
                {t("Support")}
              </h2> */}
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={() =>
                      window.open(
                        "https://www.iubenda.com/terms-and-conditions/12212628",
                        "_blank",
                        "noreferrer"
                      )
                    }
                    className="hover:underline"
                  >
                    {t("Terms & Conditions")}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      window.open(
                        "https://www.iubenda.com/privacy-policy/12212628",
                        "_blank",
                        "noreferrer"
                      )
                    }
                    className="hover:underline"
                  >
                    {t("Privacy Policy")}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <hr className="my-6 w-full max-w-md border-gray-400" />

          <div className="text-center">
            <span className="text-sm">
              © 2025 ADAPTA . {t("All Rights Reserved.")}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
