//@ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import baseInstance from "../api";
import { MapForReport } from "../components/PlacesAutocomplete";
import ProgressBar from "../components/ProgressBar";
import GaugePdf from "../components/GaugePdf";
import { useLoadScript } from "@react-google-maps/api";
import generatePDF from "react-to-pdf";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useTranslateNested from "../hooks/index";
import LanguageSwitcher from "../components/LanguageSwitcher";
import {
  getHighestRiskCategory,
  getHighestRiskValue,
  getStrongestValue,
  getStrongestCategory,
  generateTeaserText,
  calculateAdaptationPotential,
  calculateFinancialImpact,
} from "../helpers/insights";
import EnhancedGauge from "../components/GaugeStyles";

const SuccessLivestock = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState("en");
  const [myRiskdata, setMyRiskData] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("about");
  const { reportId } = useParams();
  const sessionId = searchParams.get("session_id");
  const targetRef = useRef();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });
  const climate_indices = ["Drought", "Temperature humidity"];
  const water_indices = ["Water stress", "Ground Water Availability"];
  const profitability_indices = ["Profitability"];

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const translatedData = useTranslateNested(data, localStorage.getItem("lang"));
  async function getFullReport() {
     if (!reportId) {
       navigate("/error", { state: { error: "Missing report ID" } });
       return;
     }
    setLoading(true);
    try {
      const res = await baseInstance.post(
        `/v1/b2c/free-trial/livestock/success/full-report?report_id=${reportId}`
      );
      setMyRiskData(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n?.changeLanguage(lang);
  };
  useEffect(() => {
    let temp1 = myRiskdata?.adaptations ?? {};
    let newData = {}; // Temporary object to hold data

    Object.keys(temp1).forEach((key) => {
      if (Object.keys(newData).includes(temp1[key]?.Pillar)) {
        newData = {
          ...newData,
          [temp1[key]?.Pillar]: [...newData?.[temp1[key]?.Pillar], temp1[key]],
        };
      } else {
        newData[temp1[key]?.Pillar] = [temp1[key]];
      }
    });

    // Update the state with the new data
    setData(newData);
  }, [myRiskdata]);
  useEffect(() => {
    getFullReport();
  }, []);

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Difference in milliseconds
    const differenceInTime = end - start;

    // Convert time difference to days, months, and years
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInMonths = differenceInDays / 30.44; // Approximate months
    const differenceInYears = differenceInMonths / 12;

    return {
      days: Math.floor(differenceInDays),
      months: Math.floor(differenceInMonths),
      years: differenceInYears,
    };
  };

  const { months } = calculateDateDifference(
    myRiskdata?.startDate,
    myRiskdata?.endDate
  );

   return (
     <>
       {/* Header with PDF button */}
       <div className="w-full flex justify-between items-center p-6 bg-gradient-to-r from-blue-50 to-gray-50 border-b border-gray-200">
         <div className="flex items-center">
           <img alt="Logo" className="w-30 h-auto" src="/logo.png" />
         </div>
         <button
           onClick={() =>
             generatePDF(targetRef, { filename: "adapta-report.pdf" })
           }
           className="bg-[#318CE7] hover:bg-blue-600 text-white font-medium py-2 px-6 rounded-lg inline-flex items-center shadow-md transition-all duration-200"
         >
           <svg
             className="w-5 h-5 mr-2"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
           >
             <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
           </svg>
           {t("Download PDF")}
         </button>
       </div>

       {loading ? (
         <div className="flex justify-center items-center min-h-screen">
           <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
         </div>
       ) : (
         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
           {/* Main report container */}
           <div
             id="printablediv"
             ref={targetRef}
             className="bg-white rounded-xl shadow-lg overflow-hidden"
           >
             {/* Report header */}
             <div className="bg-gradient-to-r from-blue-400 to-grey-300 p-6 text-white">
               <h2 className="text-3xl font-bold">
                 {t("Detailed Risk Report Analysis")}
               </h2>
               <p className="mt-2 opacity-90">
                 {myRiskdata?.livestock_type?.replace(/_/g, " ") || "your crop"}
                 • {months + 1} {t("months")}
               </p>
             </div>

             {/* Map and Score section */}
             <div className="grid md:grid-cols-2 gap-8 p-6">
               {/* Map section */}
               <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                 <h3 className="text-xl font-semibold text-gray-800 mb-4">
                   {t("Location Map")}
                 </h3>
                 <div className="h-64 rounded-md overflow-hidden">
                   {isLoaded && (
                     <MapForReport
                       lat={myRiskdata?.latitude}
                       lng={myRiskdata?.longitude}
                     />
                   )}
                 </div>
               </div>

               {/* Score section */}
               <div className="space-y-6">
                 <div className="mb-6">
                   <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                     {/* Dynamic icon based on risk level */}
                     {myRiskdata?.composite_total_risk > 50 ? (
                       <svg
                         className="w-5 h-5 mr-2 text-red-500"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                         />
                       </svg>
                     ) : (
                       <svg
                         className="w-5 h-5 mr-2 text-green-500"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M5 13l4 4L19 7"
                         />
                       </svg>
                     )}
                     {t("Composite Risk Score")}
                   </h3>

                   <div className="flex justify-center mb-6">
                     <ProgressBar
                       isBig={true}
                       progress={Math.floor(
                         myRiskdata?.composite_total_risk || 0
                       )}
                       width="100%"
                       color={
                         myRiskdata?.composite_total_risk > 50
                           ? "bg-red-500"
                           : "bg-green-500"
                       }
                     />
                   </div>

                   {/* Optional risk level text */}
                   <p className="text-center text-sm text-gray-600">
                     {myRiskdata?.composite_total_risk > 70
                       ? "High risk - Immediate attention recommended"
                       : myRiskdata?.composite_total_risk > 50
                       ? "Moderate risk - Consider improvements"
                       : "Low risk - Good condition"}
                   </p>
                 </div>

                 {/* Summary cards */}
                 <div className="grid grid-cols-2 gap-4">
                   <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                     <div className="flex items-center justify-between">
                       <span className="text-sm font-medium text-gray-700">
                         {t("Livestock")}
                       </span>
                       <span className="font-semibold">
                         {myRiskdata?.livestock_type?.replace(/_/g, " ") ||
                           "your livestock"}
                       </span>
                     </div>
                   </div>
                   <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                     <div className="flex items-center justify-between">
                       <span className="text-sm font-medium text-gray-700">
                         {t("Biodiversity")}
                       </span>
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="20"
                         height="20"
                         viewBox="0 0 24 24"
                         fill="none"
                         stroke="green"
                         strokeWidth="2"
                         strokeLinecap="round"
                         strokeLinejoin="round"
                       >
                         <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                         <path d="m9 12 2 2 4-4" />
                       </svg>
                     </div>
                   </div>
                   <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                     <div className="flex items-center justify-between">
                       <span className="text-sm font-medium text-gray-700">
                         {t("Biodiversity")}
                       </span>
                        <p className="text-sm">{months} Months</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="space-y-6 mb-10">
               {/* Key Insights Teaser */}
               <div className="bg-white rounded-xl p-6 border border-gray-200 shadow-xs">
                 <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                   <svg
                     className="w-5 h-5 mr-2 text-[#002d62]"
                     fill="none"
                     stroke="currentColor"
                     viewBox="0 0 24 24"
                   >
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       strokeWidth={2}
                       d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                     />
                   </svg>
                   Your Key Risk Insights
                 </h3>

                 <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                   {/* Most Critical Risk */}
                   <div className="bg-[#FFF5F5] p-4 rounded-lg border-l-4 border-red-500">
                     <h4 className="font-semibold text-gray-800 flex items-center">
                       <svg
                         className="w-4 h-4 mr-1 text-red-500"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                         />
                       </svg>
                       Highest Risk
                     </h4>
                     <p className="text-gray-600 mt-1 text-sm">
                       {getHighestRiskCategory(myRiskdata)} at{" "}
                       {getHighestRiskValue(myRiskdata)}%
                     </p>
                   </div>

                   {/* Most Positive Aspect */}
                   <div className="bg-[#F0FFF4] p-4 rounded-lg border-l-4 border-green-500">
                     <h4 className="font-semibold text-gray-800 flex items-center">
                       <svg
                         className="w-4 h-4 mr-1 text-green-500"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M5 13l4 4L19 7"
                         />
                       </svg>
                       Strongest Area
                     </h4>
                     <p className="text-gray-600 mt-1 text-sm">
                       {getStrongestCategory(myRiskdata)} at{" "}
                       {getStrongestValue(myRiskdata)}%
                     </p>
                   </div>

                   {/* Climate Impact */}
                   <div className="bg-[#FFFAF0] p-4 rounded-lg border-l-4 border-amber-500">
                     <h4 className="font-semibold text-gray-800 flex items-center">
                       <svg
                         className="w-4 h-4 mr-1 text-amber-500"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                         />
                       </svg>
                       Financial Impact
                     </h4>
                     <p className="text-gray-600 mt-1 text-sm">
                       {calculateFinancialImpact(myRiskdata)}% potential savings
                     </p>
                   </div>
                 </div>

                 <div className="bg-[#FAFAFA] p-4 rounded-lg border border-gray-200">
                   <p className="text-gray-600 text-sm mb-3">
                     <span className="font-medium text-gray-800">
                       Our analysis shows:
                     </span>{" "}
                     {generateTeaserText(myRiskdata)}
                   </p>
                 </div>
               </div>

               {/* Original Risk Sections Continue Below */}
               {/* <div className="bg-[#F9F9F9] rounded-xl p-6 border border-gray-100">
            </div> */}
             </div>
             {/* Risk indicators */}
             <div className="space-y-6 px-6 pb-6">
               {/* Climate Risk */}
               <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                 <h3 className="text-xl font-semibold text-gray-800">
                   {t("Climate Risk")}
                 </h3>
                 <div className="flex flex-wrap items-center gap-4">
                   {/* Enhanced Gauge - takes fixed width */}
                   <div className="w-32 h-32 shrink-0">
                     <EnhancedGauge
                       value={myRiskdata?.climate_scores.composite_climate_risk}
                       title="Overall"
                     />
                   </div>
                   {/* Progress Bars - flex to fill remaining space */}
                   <div className="flex-1 flex flex-wrap gap-4">
                     {/* {climate_indices.map((pillar, index) => ( */}
                     <div className="w-full md:w-[calc(33.333%-1rem)] min-w-[150px]">
                       <ProgressBar
                         width="100%" // Changed from 25% to fill container
                         piller={"Drought Risk"}
                         progress={myRiskdata?.climate_scores.drought_risk}
                       />
                     </div>
                     <div className="w-full md:w-[calc(33.333%-1rem)] min-w-[150px]">
                       <ProgressBar
                         width="100%" // Changed from 25% to fill container
                         piller={"Temperature Humidity Index"}
                         progress={
                           myRiskdata?.climate_scores
                             .temperature_humidity_index_risk
                         }
                       />
                     </div>
                     {/* ))} */}
                   </div>
                 </div>
               </div>
               <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                 <h3 className="text-xl font-semibold text-gray-800">
                   {t("Water Risk")}
                 </h3>
                 <div className="flex flex-wrap items-center gap-4">
                   {/* Enhanced Gauge - takes fixed width */}
                   <div className="w-32 h-32 shrink-0">
                     <EnhancedGauge
                       value={myRiskdata?.water_scores.composite_water_risk}
                       title="Overall"
                     />
                   </div>

                   {/* Progress Bars - flex to fill remaining space */}
                   <div className="flex-1 flex flex-wrap gap-4">
                     {/* {water_indices.map((pillar, index) => ( */}
                     <div className="w-full md:w-[calc(33.333%-1rem)] min-w-[150px]">
                       <ProgressBar
                         width="100%"
                         piller={"Ground Water"}
                         progress={myRiskdata?.water_scores.ground_water_risk}
                       />
                     </div>
                     <div className="w-full md:w-[calc(33.333%-1rem)] min-w-[150px]">
                       <ProgressBar
                         width="100%"
                         piller={"Water Stress"}
                         progress={myRiskdata?.water_scores.water_stress_risk}
                       />
                     </div>
                     {/* ))} */}
                   </div>
                 </div>
               </div>
               <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                 <h3 className="text-xl font-semibold text-gray-800">
                   {t("Profitability")}
                 </h3>
                 <div className="flex flex-wrap items-center gap-4">
                   {/* Enhanced Gauge - takes fixed width */}
                   <div className="w-32 h-32 shrink-0">
                     <EnhancedGauge
                       value={
                         myRiskdata?.profitability_scores.profitability_risk
                       }
                       title="Overall"
                     />
                   </div>

                   {/* Progress Bars - flex to fill remaining space */}
                   <div className="flex-1 flex flex-wrap gap-4">
                     {/* {soil_indices.map((pillar, index) => ( */}
                     <div className="w-full md:w-[calc(33.333%-1rem)] min-w-[150px]">
                       <ProgressBar
                         width="100%"
                         piller={"Profitability"}
                         progress={
                           myRiskdata?.profitability_scores.profitability_risk
                         }
                       />
                     </div>
                     {/* ))} */}
                   </div>
                 </div>
               </div>
             </div>
             {/* Adaptation suggestions */}
             <div className="bg-blue-50 p-6 border-t border-b border-blue-100">
               <h3 className="text-2xl font-semibold text-gray-800 mb-6">
                 {t("Climate Adaptation Suggestions")}
               </h3>
               <div className="space-y-6">
                 {Object.keys(translatedData).map((key) => (
                   <div key={key} className="bg-white p-4 rounded-lg shadow-sm">
                     <h4 className="text-lg font-semibold text-blue-700 mb-3">
                       {key}
                     </h4>
                     <ul className="space-y-2 pl-5">
                       {translatedData[key].map(({ Suggestion }, idx) => (
                         <li key={idx} className="text-gray-700 list-disc">
                           {Suggestion}
                         </li>
                       ))}
                     </ul>
                   </div>
                 ))}
               </div>
             </div>

             {/* Footer */}
             <div className="p-6 bg-gray-50">
               <div className="text-center mb-6">
                 <h4 className="text-lg font-semibold text-gray-800">
                   {t("Thank you!")}
                 </h4>
                 <p className="text-gray-600 mt-2">
                   {t(
                     "If you have any questions concerning this report, please contact us at"
                   )}
                 </p>
                 <a
                   href="mailto:info@adapta.earth"
                   className="text-blue-600 hover:underline"
                 >
                   info@adapta.earth
                 </a>
               </div>

               <div className="flex flex-col md:flex-row justify-between items-center pt-4 border-t border-gray-200">
                 <p className="text-sm text-gray-500">
                   © {new Date().getFullYear()} ADAPTA. All rights reserved.
                 </p>
                 <p className="text-sm text-gray-500 mt-2 md:mt-0">
                   {t("Report ID")}: {reportId}
                 </p>
               </div>
             </div>
           </div>

           {/* Action buttons */}
           <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
             <button
               onClick={() => navigate("/")}
               className="px-6 py-3 bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg shadow-md transition-colors duration-200"
             >
               {t("Calculate More")}
             </button>
             <button
               onClick={() => navigate("/contact-us")}
               className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg shadow-md transition-colors duration-200"
             >
               {t("Provide Feedback")}
             </button>
             <button
               onClick={() => navigate("/")}
               className="px-6 py-3 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-lg shadow-md transition-colors duration-200"
             >
               {t("Exit")}
             </button>
           </div>
         </div>
       )}
     </>
   );
};

export default SuccessLivestock;
