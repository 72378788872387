import "react-vertical-timeline-component/style.min.css";
import cropLivestock from "../assets/images/cropLivestock.png";
import coinStack from "../assets/images/coinStack.png";
import adaptation from "../assets/images/adaptation.png";
import location from "../assets/images/location.png";
import croplivestockselection from "../assets/images/croplivestockselection.png";
import { useTranslation } from "react-i18next";

const Timeline = () => {
  const { t } = useTranslation("home");

  return (
    <div className="container mx-auto px-4 py-12">
      {/* Title */}
      <h2 className="text-3xl md:text-5xl font-bold text-center text-gray-800 mb-12">
        {t("calculate climate score")}
      </h2>

      {/* Timeline Container */}
      <div className="relative">
        {/* Vertical Line - Only show on medium and larger screens */}
        <div
          className="absolute left-1/2 h-full w-0.5 bg-gray-300 transform -translate-x-1/2 hidden md:block"
          aria-hidden="true"
        ></div>

        {/* Timeline Items */}
        <div className="space-y-12">
          {/* Item 1 */}
          <div className="flex flex-col md:flex-row items-center w-full">
            {/* Image */}
            <div className="w-full md:w-5/12 p-4 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-lg shadow-lg mb-4 md:mb-0">
              <img
                loading="lazy"
                src={cropLivestock}
                alt="Crop/Livestock"
                className="w-full h-auto rounded-md"
              />
            </div>

            {/* Circle Indicator */}
            <div className="z-10 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-full border-4 border-white shadow-md mx-4">
              <span className="text-white font-semibold text-lg">1</span>
            </div>

            {/* Content */}
            <div className="w-full md:w-5/12 p-4 md:p-20">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">
                {t("Crop/Livestock Lever")}
              </h3>
              <ul className="text-sm md:text-base text-gray-600 space-y-2">
                <li>{t("Crop/Livestock Lever")}</li>
              </ul>
            </div>
          </div>

          {/* Item 2 */}
          <div className="flex flex-col md:flex-row-reverse items-center w-full">
            {/* Image */}
            <div className="w-full md:w-5/12 p-4 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-lg shadow-lg mb-4 md:mb-0">
              <img
                loading="lazy"
                src={location}
                alt="Location"
                className="w-full h-auto rounded-md"
              />
            </div>

            {/* Circle Indicator */}
            <div className="z-10 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-full border-4 border-white shadow-md mx-4">
              <span className="text-white font-semibold text-lg">2</span>
            </div>

            {/* Content */}
            <div className="w-full md:w-5/12 p-4 md:p-20">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">
                {t("Enter Location or GPS Coordinate to Find Farm")}
              </h3>
              <ul className="text-sm md:text-base text-gray-600 space-y-2">
                <li>{t("A. Google Earth window will appear.")}</li>
                <li>{t("B. Enter a landmark and look for a farm.")}</li>
                <li>{t("C. You can also enter GPS Location.")}</li>
                <li>{t("D. Use the pointer to mark lands.")}</li>
              </ul>
            </div>
          </div>

          {/* Item 3 */}
          <div className="flex flex-col md:flex-row items-center w-full">
            {/* Image */}
            <div className="w-full md:w-5/12 p-4 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-lg shadow-lg mb-4 md:mb-0">
              <img
                loading="lazy"
                src={coinStack}
                alt="Coin Stack"
                className="w-full h-auto rounded-md"
              />
            </div>

            {/* Circle Indicator */}
            <div className="z-10 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-full border-4 border-white shadow-md mx-4">
              <span className="text-white font-semibold text-lg">3</span>
            </div>

            {/* Content */}
            <div className="w-full md:w-5/12 p-4 md:p-20">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">
                {t("Enter Years to Forecast or Loan Period")}
              </h3>
              <ul className="text-sm md:text-base text-gray-600 space-y-2">
                <li>{t("A. You scroll and select the forecast period.")}</li>
                <li>
                  {t(
                    "B. Climate change is visible over more extended periods."
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* Item 4 */}
          <div className="flex flex-col md:flex-row-reverse items-center w-full">
            {/* Image */}
            <div className="w-full md:w-5/12 p-4 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-lg shadow-lg mb-4 md:mb-0">
              <img
                loading="lazy"
                src={croplivestockselection}
                alt="Crop/Livestock Selection"
                className="w-full h-auto rounded-md"
              />
            </div>

            {/* Circle Indicator */}
            <div className="z-10 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-full border-4 border-white shadow-md mx-4">
              <span className="text-white font-semibold text-lg">4</span>
            </div>

            {/* Content */}
            <div className="w-full md:w-5/12 p-4 md:p-20">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">
                {t("Select Crop/Livestock")}
              </h3>
              <ul className="text-sm md:text-base text-gray-600 space-y-2">
                <li>
                  {t(
                    "A. If you moved the lever to crop, you get a list of crops to select."
                  )}
                </li>
                <li>
                  {t("B. If moved lever to livestock, select livestock type.")}
                </li>
                <li>
                  {t(
                    "C. Only for livestock, you will be asked questions to measure profitability risk."
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* Item 5 */}
          <div className="flex flex-col md:flex-row items-center w-full">
            {/* Image */}
            <div className="w-full md:w-5/12 p-4 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-lg shadow-lg mb-4 md:mb-0">
              <img
                loading="lazy"
                src={adaptation}
                alt="Adaptation"
                className="w-full h-auto rounded-md"
              />
            </div>

            {/* Circle Indicator */}
            <div className="z-10 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-gradient-to-r from-[#7A5A44] to-[#002d62] rounded-full border-4 border-white shadow-md mx-4">
              <span className="text-white font-semibold text-lg">5</span>
            </div>

            {/* Content */}
            <div className="w-full md:w-5/12 p-4 md:p-20">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">
                {t("Adaptation Suggestions")}
              </h3>
              <ul className="text-sm md:text-base text-gray-600 space-y-2">
                <li>
                  {t(
                    "ADAPTA CS provides you a set of suggestions to adapt to or enhance the resilience of the farm and the creditworthiness of a farmer."
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
