// Helper functions for the summary section
export const getHighestRiskCategory = (data:any) => {
  const risks = [
    { name: "Climate", value: data?.climate_scores?.composite_climate_risk },
    { name: "Water", value: data?.water_scores?.composite_water_risk },
    { name: "Soil", value: data?.soil_scores?.composite_soil_risk },
  ].filter((item) => item.value !== undefined);

  return risks.length > 0
    ? risks.sort((a, b) => b.value - a.value)[0].name
    : "No data";
};
export const getHighestRiskCategoryLivestock = (data:any) => {
  const risks = [
    { name: "Climate", value: data?.climate_scores?.composite_climate_risk },
    { name: "Water", value: data?.water_scores?.composite_water_risk },
    { name: "Profitability", value: data?.profitability_scores.profitability_risk },
  ].filter((item) => item.value !== undefined);

  return risks.length > 0
    ? risks.sort((a, b) => b.value - a.value)[0].name
    : "No data";
};

export const getHighestRiskValue = (data:any) => {
  const values = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.soil_scores?.composite_soil_risk,
  ].filter((v) => v !== undefined);

  return values.length > 0 ? Math.max(...values) : 0;
};
export const getHighestRiskValueLivestock = (data:any) => {
  const values = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.profitability_scores?.profitability_risk,
  ].filter((v) => v !== undefined);

  return values.length > 0 ? Math.max(...values) : 0;
};

export const getStrongestCategory = (data:any) => {
  const risks = [
    { name: "Climate", value: data?.climate_scores?.composite_climate_risk },
    { name: "Water", value: data?.water_scores?.composite_water_risk },
    { name: "Soil", value: data?.soil_scores?.composite_soil_risk },
  ].filter((item) => item.value !== undefined);

  return risks.length > 0
    ? risks.sort((a, b) => a.value - b.value)[0].name
    : "No data";
};
export const getStrongestCategoryLivestock = (data:any) => {
  const risks = [
    { name: "Climate", value: data?.climate_scores?.composite_climate_risk },
    { name: "Water", value: data?.water_scores?.composite_water_risk },
    { name: "Profitability", value: data?.profitability_scores.profitability_risk },
  ].filter((item) => item.value !== undefined);

  return risks.length > 0
    ? risks.sort((a, b) => a.value - b.value)[0].name
    : "No data";
};

export const getStrongestValue = (data:any) => {
  const values = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.soil_scores?.composite_soil_risk,
  ].filter((v) => v !== undefined);

  return values.length > 0 ? Math.min(...values) : 0;
};
export const getStrongestValueLivestock = (data:any) => {
  const values = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.profitability_scores?.profitability_risk,
  ].filter((v) => v !== undefined);

  return values.length > 0 ? Math.min(...values) : 0;
};

export const generateTeaserText = (data:any) => {
  if (!data) return "Loading insights...";

  const highest = getHighestRiskCategory(data);
  const lowest = getStrongestCategory(data);
  const crop = data?.crop || "your crop";

  const teasers = [
    `Your ${crop} shows ${lowest.toLowerCase()} resilience but faces ${highest.toLowerCase()} challenges.`,
    `While ${lowest.toLowerCase()} conditions are favorable, ${highest.toLowerCase()} risks need attention.`,
    // `The analysis reveals strong ${lowest.toLowerCase()} performance but ${highest.toLowerCase()} vulnerabilities.`,
  ];

  // return teasers[Math.floor(Math.random() * teasers.length)];
  return teasers;
};
export const generateLivestockTeaserText = (data:any) => {
  if (!data) return "Loading insights...";

  const highest = getHighestRiskCategory(data);
  const lowest = getStrongestCategory(data);
  const livestock = data?.livestock_type?.replace(/_/g, ' ') || "your crop";


  const teasers = [
    `Your ${livestock} shows ${lowest.toLowerCase()} resilience but faces ${highest.toLowerCase()} challenges.`,
    `While ${lowest.toLowerCase()} conditions are favorable, ${highest.toLowerCase()} risks need attention.`,
    // `The analysis reveals strong ${lowest.toLowerCase()} performance but ${highest.toLowerCase()} vulnerabilities.`,
  ];

  // return teasers[Math.floor(Math.random() * teasers.length)];
  return teasers;
};
// Helper function
export const calculateAdaptationPotential = (data:any) => {
  const lowRiskThreshold = 30;
  const mediumRiskThreshold = 60;
  
  const risks = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.soil_scores?.composite_soil_risk
  ].filter(v => v !== undefined);

  const count = risks.filter(v => v > lowRiskThreshold).length;
  
  if (count === 0) return "5+";
  if (risks.some(v => v > mediumRiskThreshold)) return "8-10";
  return "3-5";
};

export const calculateAdaptationUrgency = (data: any): number => {
  const risks = [
    data?.climate_scores?.composite_climate_risk,
    data?.water_scores?.composite_water_risk,
    data?.soil_scores?.composite_soil_risk
  ].filter(v => v !== undefined);
  
  return risks.length > 0 
    ? Math.floor(risks.reduce((a,b) => a + b, 0) / risks.length)
    : 0;
};
export const calculateFinancialImpact = (data:any) => {
  const compositeRisk = data?.composite_total_risk || 0;
  return Math.floor((100 - compositeRisk) * 0.3); // Converts risk to potential savings
};