import ProgressBar from "./ProgressBar";

export const RiskItem = ({ title, progress, color = "bg-blue-500" }:any) => (
  <div className="flex flex-col items-center p-3 bg-white rounded-lg border border-gray-200">
    <div className="w-full mb-2">
      <ProgressBar
        width="100%"
        progress={progress}
        color={color} // Pass color to your ProgressBar
      />
    </div>
    <span className="text-sm font-medium text-gray-700">{title}</span>
    <span className="text-xs text-gray-500 mt-1">{progress}% risk</span>
  </div>
);
