import baseInstance from "../api";


const checkEmailQuota = async (email: string) => {
  try {
    const response = await baseInstance.get(
      `/v1/b2c/free-trial/user/check-email?email=${encodeURIComponent(email)}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { checkEmailQuota };