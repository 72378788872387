// ErrorPage.jsx
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const error = location.state?.error || "Unknown error occurred";
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
      <p className="mb-6">{error}</p>
      <button
        onClick={() => navigate("/")}
        className="px-4 py-2 bg-blue-600 text-white rounded"
      >
        Return Home
      </button>
    </div>
  );
};

export default ErrorPage;